import PrintIcon from '@mui/icons-material/Print';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import * as React from 'react';
import EdittableDatagrid, {
    EDITTABLE_DATAGRID_NEW_ROW_ID,
} from '../../../common/components/datagrid/EdittableDatagrid';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import authenticationApi from '../../authentication/authenticationApi';
import { AssignmentRecord, UpdateAssignmentRequest } from '../models';
import printerApi from '../printerApi';
import { EditComputer } from './assignment/EditComputer';
import { EditPaperSize } from './assignment/EditPaperSize';
import { EditPrinter } from './assignment/EditPrinter';

const TEST_ZPL = `
^XA
^FX Top section with logo, name and address.
^CF0,60
^FO50,50^GB100,100,100^FS
^FO75,75^FR^GB100,100,100^FS
^FO93,93^GB40,40,40^FS
^FO220,50^FD1Intershipping, Inc.^FS
^CF0,30
^FO220,115^FD1000 Shipping Lane^FS
^FO220,155^FDShelbyville TN 38102^FS
^FO220,195^FDUnited States (USA)^FS
^FO50,250^GB700,3,3^FS

^FX Second section with recipient address and permit information.
^CFA,30
^FO50,300^FDJohn Doe^FS
^FO50,340^FD100 Main Street^FS
^FO50,380^FDSpringfield TN 39021^FS
^FO50,420^FDUnited States (USA)^FS
^CFA,15
^FO600,300^GB150,150,3^FS
^FO638,340^FDPermit^FS
^FO638,390^FD123456^FS
^FO50,500^GB700,3,3^FS

^FX Third section with bar code.
^BY5,2,270
^FO100,550^BC^FD12345678^FS

^FX Fourth section (the two boxes on the bottom).
^FO50,900^GB700,250,3^FS
^FO400,900^GB3,250,3^FS
^CF0,40
^FO100,960^FDCtr. X34B-1^FS
^FO100,1010^FDREF1 F00B47^FS
^FO100,1060^FDREF2 BL4H8^FS
^CF0,190
^FO470,955^FDCA^FS

^XZ`;

export default function AssignmentDatagrid({ assignments }: { assignments: AssignmentRecord[] }) {
    const [rows, setRows] = React.useState(assignments);

    const getComputersResponse = printerApi.endpoints.getComputers.useQuery();
    const getPrintersResponse = printerApi.endpoints.getPrinters.useQuery();
    const getPaperSizesResponse = printerApi.endpoints.getPaperSizes.useQuery();

    const getSystemPropertiesResponse = authenticationApi.endpoints.getSystemProperties.useQuery();

    const initialRow: UpdateAssignmentRequest = {
        Id: EDITTABLE_DATAGRID_NEW_ROW_ID,
        ComputerId:
            getComputersResponse?.data?.find(computer => computer.Name === getSystemPropertiesResponse.data?.HostName)
                ?.Id ||
            getComputersResponse?.data?.[0]?.Id ||
            -1,
        PrinterId: getPrintersResponse?.data?.[0]?.Id || -1,
        PaperSizeId: getPaperSizesResponse?.data?.[0]?.Id || -1,
    };

    const [triggerCreate, createResponse] = printerApi.endpoints.createAssignment.useMutation();
    const [triggerUpdate, updateResponse] = printerApi.endpoints.updateAssignment.useMutation();
    const [triggerDelete, deleteResponse] = printerApi.endpoints.deleteAssignment.useMutation();

    useMutationResponse({ response: createResponse, errorMessage: `Failed to create assignment` });
    useMutationResponse({ response: updateResponse, errorMessage: `Failed to update assignment` });
    useMutationResponse({ response: deleteResponse, errorMessage: `Failed to delete assignment` });

    const [triggerPrint, printResponse] = printerApi.endpoints.print.useMutation();

    const handlePrintClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        const matchingPrinter = getPrintersResponse?.data?.find(row => row.Id === matchingRow.PrinterId);
        if (!matchingPrinter) return;

        const matchingPaperSize = getPaperSizesResponse?.data?.find(row => row.Id === matchingRow.PaperSizeId);
        if (!matchingPaperSize) return;

        triggerPrint({
            Base64EncodedPrintData: btoa(TEST_ZPL),
            FileType: 'ZPL',
            Transformation: {
                Rotation: 0,
                Scale: {
                    Multiplier: 1,
                    FitToSize: {
                        TargetWidth: matchingPaperSize.WidthInInches,
                        TargetHeight: matchingPaperSize.HeightInInches,
                    },
                },
                ShouldInvertOrientation: false,
            },
            PrinterName: matchingPrinter?.Name || '',
            ShouldConvertToZpl: true,
        });
    };

    const handleDeleteClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        triggerDelete(matchingRow.Id);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const matchingRow = { ...newRow } as AssignmentRecord;

        if (!matchingRow) return matchingRow;

        setRows(rows.map(row => (row.Id === newRow.Id ? matchingRow : row)));

        if (newRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) triggerCreate(matchingRow);
        else triggerUpdate(matchingRow);

        return matchingRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: 'Id',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (value: any) => (value === EDITTABLE_DATAGRID_NEW_ROW_ID ? '-' : value),
        },
        {
            field: 'ComputerId',
            headerName: 'Computer',
            flex: 4,
            editable: true,
            type: 'singleSelect',
            valueOptions: getComputersResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditComputer {...props} />,
        },
        {
            field: 'PrinterId',
            headerName: 'Printer',
            flex: 4,
            editable: true,
            type: 'singleSelect',
            valueOptions: getPrintersResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditPrinter {...props} />,
        },
        {
            field: 'PaperSizeId',
            headerName: 'Paper Size',
            flex: 2,
            editable: true,
            type: 'singleSelect',
            valueOptions: getPaperSizesResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditPaperSize {...props} />,
        },
    ];

    return (
        <EdittableDatagrid
            rows={rows}
            shouldStopEditingOnEnter={false}
            setRows={setRows}
            initialRow={initialRow}
            getRowId={row => row.Id}
            columns={columns}
            handleDeleteClick={handleDeleteClick}
            processRowUpdate={processRowUpdate}
            nonEditModeAdditionalActions={id => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Print Test Page">
                            <PrintIcon />
                        </Tooltip>
                    }
                    onClick={() => {
                        handlePrintClick(+id);
                    }}
                    label="Print"
                    color="inherit"
                />,
            ]}
        />
    );
}
