import { Box, DialogContentText } from '@mui/material';
import { useParams } from 'react-router';
import receiversApi from '../../../../../receiversApi';

const ExtraSerialHeader = () => {
    const routeParams: any = useParams();

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    const isSerialAddingDone = !getReceiverResponse?.data?.RequestAdditionalSerialScans;

    return (
        <Box sx={{ mb: 1 }}>
            <DialogContentText variant="h5" color={'error'}>
                <b>Captured more than the number of expected serials.</b>
            </DialogContentText>
            {!isSerialAddingDone && (
                <DialogContentText variant="h5" color={'error'}>
                    <b>Delete the bad serial in the right-side table or</b>
                </DialogContentText>
            )}
            <DialogContentText variant="h5" color={'error'}>
                <b>If correct, then modify receiver (#{routeParams.receiverId}) with correct quantities.</b>
            </DialogContentText>
        </Box>
    );
};

export default ExtraSerialHeader;
