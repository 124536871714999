import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { setDialog } from '../../../../notification/notificationSlice';
import { SubmissionFormItem } from '../../../receiversModels';
import { setForm } from '../../../receiversSlice';
import AddItemDialog from '../../createDialog/formPartTwo/items/addItemDialog/AddItemDialog';
import ItemsDataGrid from './ItemsDataGrid';

const Items = ({ hasAsn }: { hasAsn: boolean }) => {
    const dispatch = useAppDispatch();
    const { form, mode } = useAppSelector(state => state.receivers);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isAddingNewItem, setIsAddingNewItem] = React.useState(false);
    const [itemToUpsert, setItemToUpsert] = React.useState<SubmissionFormItem | undefined>(undefined);
    const [upcOnAsnToUpdate, setUpcOnAsnToUpdate] = React.useState('');

    const isDisabled = form.Played;

    const handleItemClick = React.useCallback(
        (item: SubmissionFormItem) => {
            if (item.LineId || mode === 'View') return;
            setIsDialogOpen(true);
            setIsAddingNewItem(false);
            setItemToUpsert(item);
        },
        [form.Items, mode]
    );

    const handleItemDelete = React.useCallback(
        (itemToDelete: SubmissionFormItem) => {
            dispatch(
                setForm({
                    ...form,
                    Items: form.Items.filter(item => JSON.stringify(item) !== JSON.stringify(itemToDelete)),
                })
            );
        },
        [form.Items, mode]
    );

    const handleUpdateUpcButtonClick = () => {
        const itemToUpdate = form.Items.find(item => {
            return (
                (item.Upc === itemToUpsert?.Upc?.trim() || item.Upc === upcOnAsnToUpdate) &&
                (item.LineId ? item.RecQty === 0 : true)
            );
        });
        if (!itemToUpdate) {
            dispatch(setDialog({ content: `UPC not part of ASN or already scanned`, title: `Invalid Item` }));
            return;
        }

        setItemToUpsert(itemToUpdate);
        setIsDialogOpen(true);
        setIsAddingNewItem(false);
    };

    // useScanner(skuToUpdate, handleUpdateUpcButtonClick);

    return (
        <Accordion sx={{ my: 2 }} elevation={8} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="labor-time-content" id="labor-time-header">
                {`Items ${isDisabled ? '(Disabled- already played)' : ''}`}
            </AccordionSummary>
            <AccordionDetails>
                {mode !== 'View' && hasAsn && (
                    <Box display={'flex'} sx={{ mb: 2 }}>
                        <TextField
                            value={upcOnAsnToUpdate}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setUpcOnAsnToUpdate(newValue);
                            }}
                            label="Scan UPC on ASN to update"
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    handleUpdateUpcButtonClick();
                                }
                            }}
                        />

                        <Button
                            sx={{ m: 2 }}
                            variant="contained"
                            color="secondary"
                            onClick={handleUpdateUpcButtonClick}
                        >
                            Update Existing Item
                        </Button>
                    </Box>
                )}

                <ItemsDataGrid
                    items={form?.Items || []}
                    handleItemClick={handleItemClick}
                    hasAsn={hasAsn}
                    handleItemDelete={handleItemDelete}
                />

                {isDialogOpen && (
                    <AddItemDialog
                        itemToUpsert={itemToUpsert}
                        setItemToUpsert={setItemToUpsert}
                        handleDialogClose={() => {
                            setIsDialogOpen(false);
                            setItemToUpsert(undefined);
                            setUpcOnAsnToUpdate('');
                        }}
                        isAddingNewItem={isAddingNewItem}
                        hasAsn={hasAsn}
                    />
                )}

                {mode !== 'View' && (
                    <Button
                        onClick={() => {
                            if (!form.Client) {
                                dispatch(
                                    setDialog({
                                        content: `Must select company before attempting to add item`,
                                        title: `Company Required`,
                                    })
                                );
                                return;
                            }
                            setUpcOnAsnToUpdate('');
                            setIsDialogOpen(true);
                            setIsAddingNewItem(true);
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        variant="contained"
                        color="secondary"
                    >
                        Add New Item
                    </Button>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default Items;
