import { GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/query';
import * as React from 'react';
import { useAppDispatch } from '../../../app/store';
import EdittableDatagrid, {
    EDITTABLE_DATAGRID_NEW_ROW_ID,
} from '../../../common/components/datagrid/EdittableDatagrid';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import { setDialog } from '../../notification/notificationSlice';
import { GetPrintPropertiesResponse, PaperSizeRecord, UpdatePaperSizeRequest } from '../models';
import printerApi from '../printerApi';
import { EditPaperName } from './EditPaperName';
import PrinterNameAutocomplete from './PrinterNameAutocomplete';

export default function PaperSizeDatagrid({
    paperSizes,
    onRowClick = () => {},
}: {
    paperSizes: PaperSizeRecord[];
    onRowClick?: (id: number) => void;
}) {
    const [rows, setRows] = React.useState(paperSizes);
    const dispatch = useAppDispatch();

    const [printerName, setPrinterName] = React.useState('');
    const getLocalPaperSizesResponse = printerApi.endpoints.getLocalPaperSizes.useQuery(
        printerName
            ? {
                  PrinterName: printerName,
              }
            : skipToken
    );

    const initialRow: UpdatePaperSizeRequest = {
        Id: EDITTABLE_DATAGRID_NEW_ROW_ID,
        Name: '',
        HeightInInches: 0,
        WidthInInches: 0,
    };

    const [triggerCreate, createResponse] = printerApi.endpoints.createPaperSize.useMutation();
    const [triggerUpdate, updateResponse] = printerApi.endpoints.updatePaperSize.useMutation();
    const [triggerDelete, deleteResponse] = printerApi.endpoints.deletePaperSize.useMutation();

    useMutationResponse({ response: createResponse, errorMessage: `Failed to create paper size` });
    useMutationResponse({ response: updateResponse, errorMessage: `Failed to update paper size` });
    useMutationResponse({ response: deleteResponse, errorMessage: `Failed to delete paper size` });

    const handleDeleteClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        triggerDelete(matchingRow.Id);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const matchingRow = { ...newRow } as PaperSizeRecord;

        if (!matchingRow) return matchingRow;

        if (!matchingRow.Name) {
            dispatch(setDialog({ content: 'Name field is required' }));
            return matchingRow;
        }

        setRows(rows.map(row => (row.Id === newRow.Id ? matchingRow : row)));

        if (newRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) triggerCreate(matchingRow);
        else triggerUpdate(matchingRow);

        return matchingRow;
    };

    const columns: GridColDef<PaperSizeRecord>[] = [
        {
            field: 'Id',
            headerName: 'Id',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (value: any) => (value === EDITTABLE_DATAGRID_NEW_ROW_ID ? '-' : value),
        },
        {
            field: 'Name',
            headerName: 'Paper Name',
            type: 'singleSelect',
            valueOptions: getLocalPaperSizesResponse.data || [],
            valueFormatter: (value: GetPrintPropertiesResponse) => value?.PaperName,
            flex: 3,
            editable: true,
            renderEditCell: EditPaperName,
        } as GridColDef<any, GetPrintPropertiesResponse, string>,
        {
            field: 'WidthInInches',
            headerName: 'Width (inches)',
            flex: 2,
            type: 'number',
            editable: true,
        },
        {
            field: 'HeightInInches',
            headerName: 'Height (inches)',
            flex: 2,
            type: 'number',
            editable: true,
        },
    ];

    return (
        <>
            <PrinterNameAutocomplete
                printerName={printerName}
                handleInputChange={newPrinterName => setPrinterName(newPrinterName)}
            />

            <EdittableDatagrid
                rows={rows}
                setRows={setRows}
                initialRow={initialRow}
                getRowId={row => row.Id}
                columns={columns}
                handleDeleteClick={handleDeleteClick}
                processRowUpdate={processRowUpdate}
                onRowClick={(params, event) => onRowClick(+params.id)}
            />
        </>
    );
}
