import { Box } from '@mui/material';
import React from 'react';
import { ReceivedItem, ReceivedItemSerial, ReceivedItemUnitDetail } from '../../../rmaModels';
import { ReceivedItemUnitDetailIndividualFormDisposition } from '../ReceivedItemUnitDetailIndividualFormDisposition';
import { ReceivedItemUnitDetailBulkFormSerial } from './ReceivedItemUnitDetailBulkFormSerial';

export const ReceivedItemUnitDetailBulkStatusAssignmentForm = React.memo(
    ({
        editingItem,
        setEditingItem,
        editingItemUnit,
        setEditingItemUnit,
        serials,
        setSerials,
        showErrors = false,
    }: {
        editingItem: ReceivedItem;
        setEditingItem: (item: ReceivedItem) => void;
        editingItemUnit: ReceivedItemUnitDetail;
        serials: ReceivedItemSerial[];
        setSerials: (serials: ReceivedItemSerial[]) => void;
        setEditingItemUnit: (
            unit: ReceivedItemUnitDetail | ((prevUnit: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
        ) => void;
        showErrors?: boolean;
    }) => {
        return (
            <Box sx={{ my: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                        alignContent: 'stretch',
                        mx: 3,
                        pb: 3,
                    }}
                >
                    <Box sx={{ ml: 2, mr: 2, flex: 2 }}>
                        <ReceivedItemUnitDetailIndividualFormDisposition
                            editingItemUnit={editingItemUnit}
                            setEditingItemUnit={setEditingItemUnit}
                            showErrors={showErrors}
                            editingItem={editingItem}
                        />
                    </Box>
                    <ReceivedItemUnitDetailBulkFormSerial
                        editingItem={editingItem}
                        setEditingItem={setEditingItem}
                        editingItemUnit={editingItemUnit}
                        setReceivedItemUnit={setEditingItemUnit}
                        serials={serials}
                        setSerials={setSerials}
                        showErrors={showErrors}
                    />
                </Box>
            </Box>
        );
    }
);
