import { ReceivedItem, ReceivedItemUnitDetail, Rma } from './rmaModels';

export const NEW_STATUS = 'NEW';
export const COMPLETE_STATUS = 'COMPLETE';
export const ERROR_STATUS = 'ERROR';
export const NEW_STATUS_ID = 1;
export const COMPLETE_STATUS_ID = 2;
export const ERROR_STATUS_ID = 3;
export const RMA_STATUS: { [key: number]: string } = { 1: NEW_STATUS, 2: COMPLETE_STATUS, 3: ERROR_STATUS };

export const SKU_IMG_HEIGHT = { xs: 233, md: 167 };
export const SKU_IMG_WIDTH = { xs: 370, md: 260 };

export const BULK_UNIT_THRESHOLD_QTY = 10;
export const DISPOSITION_OTHER_ID = 4;
export const MISSING_SERIAL_REASON_OTHER_ID = 3;

export const SERIAL_QTY_LIMIT_PER_UNIT = 1;
export const CLIENT_CODE_REGEX_FROM_REF_PO = /^[A-Za-z]{3,4}/;

export const RMA_PROCESS_SLUG = 'process';
export const RMA_REPORT_SLUG = 'report';

export const RMA_HISTORY_STATUS_PENDING = 'RTSEXP';

export const NEW_RECEIVED_ITEM: ReceivedItem = {
    Id: null,
    RmaId: null,
    Quantity: null,
    ExpectedItemId: null,
    Disposition: null,
    NotFound: false,
    UnknownItem: false,
    Serials: [],
    ItemUnitDetails: [],
    Sku: null,
    Upc: null,
    SerialVerify: null,
    IsSerialized: false,
    AllowSerialDup: false,
    Description: null,
    ValidToSubmit: false,
    ValidDispositions: [],
    ExpectedDisposition: null,
};

export const NEW_RECEIVED_ITEM_UNIT_DETAIL: ReceivedItemUnitDetail = {
    SerialNotes: null,
    DispositionNotes: null,
    Serials: [],
    Disposition: null,
    MissingSerialReason: null,
    ValidToSubmit: false,
};

export const NEW_RMA: Rma = {
    Id: null,
    Client: null,
    TrackingNumbers: [],
    AltRmaNumber: null,
    RmaNumber: null,
    ReceivedItems: [],
    ExpectedItems: [],
    Status: NEW_STATUS_ID,
    Notes: null,
    DateAdded: null,
    DateUpdated: null,
    ProcessedBy: null,
    ProcessedHistory: null,
    OriginalOrderDetails: null,
    OriginalOrderId: null,
    ParentRmaId: null,
};

export const SEARCH_ALTRMA_NUMBER = 'SEARCH_ALTRMA_NUMBER';
export const SEARCH_RMA_ID = 'SEARCH_RMA_ID';
export const SEARCH_TRACKING = 'SEARCH_TRACKING';
export const RMA_REPORT_RESULTS_DEFAULT_COUNT = 100;
export const RMA_REPORT_RESULTS_MAX_COUNT = 1000000;

export const REPORT_SEARCH_BY_OPTIONS: { [key: string]: string } = {
    SEARCH_TRACKING: 'Tracking',
    SEARCH_RMA_ID: 'RMA',
    SEARCH_RMA_NUMBER: 'Alternative RMA',
};

export const INPUT_DATETIME_FORMAT = 'MM/DD/YYYY HH:00:00';
export const DEFAULT_FROM_SEARCH_DAYS_BACK = 30;
