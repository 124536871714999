import {
    blue,
    cyan,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@mui/material/colors';
import { SerialData } from '../../../../receiversModels';

interface RowStatusModel {
    DisplayName: string;
    CssClassName: string;
    Color: string;
    ColorHover: string;
}

export const DUPLICATE_SERIAL_COLORS = [
    red['A200'],
    pink['A200'],
    purple['A200'],
    deepPurple['A200'],
    indigo['A200'],
    blue['A200'],
    lightBlue['A200'],
    cyan['A200'],
    teal['A200'],
    yellow['A200'],
];

export const ScanSerialRowColor = {
    Synced: {
        CssClassName: 'Synced',
        Color: green['400'],
    },
    Rejected: {
        CssClassName: 'Rejected',
        Color: 'lightred',
    },
    Pending: {
        CssClassName: 'Pending',
        Color: green['200'],
    },
};

export const RowStatuses = {
    Hidden: {
        DisplayName: 'Hidden',
        CssClassName: 'Hidden',
        Color: grey['A100'],
        ColorHover: grey['A200'],
    },
    RegexError: {
        DisplayName: 'Regex Error',
        CssClassName: 'Error',
        Color: red['A400'],
        ColorHover: red['A700'],
    },
    InvalidCountError: {
        DisplayName: 'Invalid Count Error',
        CssClassName: 'Error',
        Color: red['A100'],
        ColorHover: red['A200'],
    },
    Complete: {
        DisplayName: 'Complete',
        CssClassName: 'Complete',
        Color: green['A100'],
        ColorHover: green['A200'],
    },
    NotStarted: {
        DisplayName: 'Not Started',
        CssClassName: 'NotStarted',
        Color: yellow['A100'],
        ColorHover: yellow['A200'],
    },
    InProgress: {
        DisplayName: 'In Progress',
        CssClassName: 'InProgress',
        Color: orange['A100'],
        ColorHover: orange['A200'],
    },
};

export const getRowStatus = (row: SerialData, isCompleted: boolean = false) => {
    if (isCompleted) return RowStatuses.Complete;
    else if (isSerialDataHidden(row)) return RowStatuses.Hidden;
    else if (isRegexError(row)) return RowStatuses.RegexError;
    else if (isInvalidCountError(row)) return RowStatuses.InvalidCountError;
    else if (row.CurrentScannedCount === row.TotalSerialsToScan) return RowStatuses.Complete;
    else if (row.CurrentScannedCount <= 0) return RowStatuses.NotStarted;
    return RowStatuses.InProgress;
};

export const isRegexError = (row: SerialData) => {
    return row.IsSerialized && !row.Regex;
};

export const isInvalidCountError = (row: SerialData) => {
    return row.CurrentScannedCount > row.TotalSerialsToScan;
};

export const isSerialDataHidden = (row: SerialData) => {
    return !row.HasInboundSerial;
};
