import { Box } from '@mui/material';
import React from 'react';
import { ReceivedItem } from '../../rmaModels';
import { ReceivedItemUnitDetailIndividualForm } from './ReceivedItemUnitDetailIndividualForm';
import { BULK_UNIT_THRESHOLD_QTY } from '../../constants';

export const ReceivedItemUnitDetailForm = React.memo(
    ({
        editingItem,
        setEditingItem,
        showErrors = false,
    }: {
        editingItem: ReceivedItem;
        setEditingItem: (item: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
        showErrors?: boolean;
    }) => {
        if (!editingItem.ItemUnitDetails || editingItem.ItemUnitDetails.length < 1) {
            return <Box sx={{ mt: 3 }} />;
        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {editingItem.ItemUnitDetails.slice(0, BULK_UNIT_THRESHOLD_QTY).map((unit, index) => (
                    <ReceivedItemUnitDetailIndividualForm
                        unitIndex={index}
                        editingItem={editingItem}
                        setEditingItem={setEditingItem}
                        showErrors={showErrors}
                    />
                ))}
            </Box>
        );
    }
);
