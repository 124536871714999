import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { useEffect } from 'react';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import { Rma } from '../rmaModels';

const useSetRmaSearchResults = ({
    response,
    setRmaSearchResults,
}: {
    response: TypedUseQueryStateResult<any, unknown, any>;
    setRmaSearchResults: (value: Rma[]) => void;
}) => {
    useNotificationByResponse({
        isFetching: response.isFetching,
        isError: response.isError,
        errorContent: 'Could not get RMAs.',
        isUninitialized: response.isUninitialized,
    });

    useEffect(() => {
        if (response?.data?.Data && response.data.Data.length >= 0) {
            setRmaSearchResults(response.data.Data);
        }
    }, [response, setRmaSearchResults]);
};

export default useSetRmaSearchResults;
