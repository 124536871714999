import { NoteAdd } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../../../app/store';
import { TypeSafeColDef } from '../../../../../../common/utils/extendedTypes';
import { setDialog } from '../../../../../notification/notificationSlice';
import receiversApi from '../../../../receiversApi';
import { SerialData } from '../../../../receiversModels';
import AddSerialDialog from './addSerialDialog/AddSerialDialog';
import { getRowStatus, isSerialDataHidden, RowStatuses } from './constants';

const AddSerialDatagrid = () => {
    const routeParams: any = useParams();
    const dispatch = useAppDispatch();

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    const getScannedSerialsResponse = receiversApi.endpoints.getAddSerialData.useQuery({
        ReceiverId: routeParams.receiverId,
        ShouldIncludeCompletedSerials: !getReceiverResponse?.data?.RequestAdditionalSerialScans,
    });

    const [itemToAddSerialsTo, setItemToAddSerialsTo] = React.useState<SerialData | undefined>();

    const handleAddSerialsClick = (row: SerialData) => () => {
        if (row.IsSerialized != row.HasInboundSerial) {
            dispatch(setDialog({ content: `This item\'s serial configurations are invalid. Please inform IT.` }));
            return;
        }

        if (!row.Regex) {
            dispatch(setDialog({ content: 'This item has no regular expression. Please inform IT.' }));
            return;
        }

        setItemToAddSerialsTo(row);
    };

    const columns: TypeSafeColDef<SerialData>[] = [
        {
            field: 'Sku',
            headerName: 'Sku',
            flex: 3,
        },
        {
            field: 'calculated',
            headerName: 'Status',
            flex: 3,
            valueGetter: (_, row) =>
                getRowStatus(row, !getReceiverResponse.data?.RequestAdditionalSerialScans).DisplayName,
        },
        {
            field: 'CurrentScannedCount',
            headerName: 'Current Scanned Count',
            flex: 2,
            type: 'number',
        },
        {
            field: 'TotalSerialsToScan',
            headerName: 'Total Serials To Scan',
            flex: 2,
            type: 'number',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 1,
            cellClassName: 'actions',
            getActions: params => {
                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Add Serial">
                                <IconButton>
                                    <NoteAdd />
                                </IconButton>
                            </Tooltip>
                        }
                        label="Add Serial"
                        className="textPrimary"
                        onClick={handleAddSerialsClick(params.row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleDialogClose = () => {
        setItemToAddSerialsTo(undefined);
        dispatch(receiversApi.util.invalidateTags(['serialCacheHeader', 'receiversDataGrid']));
    };

    const rows = getScannedSerialsResponse?.data?.filter(row => !isSerialDataHidden(row));

    return (
        <>
            <DataGrid
                getRowId={row => row.ReceiverDataId}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                getRowClassName={({ row }: { row: SerialData }) => {
                    return getRowStatus(row, !getReceiverResponse.data?.RequestAdditionalSerialScans).CssClassName;
                }}
                sx={{
                    [`& .${RowStatuses.RegexError.CssClassName}`]: {
                        backgroundColor: RowStatuses.RegexError.Color,
                        '&:hover': { backgroundColor: RowStatuses.RegexError.ColorHover },
                    },

                    [`& .${RowStatuses.InvalidCountError.CssClassName}`]: {
                        backgroundColor: RowStatuses.InvalidCountError.Color,
                        '&:hover': { backgroundColor: RowStatuses.InvalidCountError.ColorHover },
                    },

                    [`& .${RowStatuses.NotStarted.CssClassName}`]: {
                        backgroundColor: RowStatuses.NotStarted.Color,
                        '&:hover': { backgroundColor: RowStatuses.NotStarted.ColorHover },
                    },

                    [`& .${RowStatuses.InProgress.CssClassName}`]: {
                        backgroundColor: RowStatuses.InProgress.Color,
                        '&:hover': { backgroundColor: RowStatuses.InProgress.ColorHover },
                    },

                    [`& .${RowStatuses.Complete.CssClassName}`]: {
                        backgroundColor: RowStatuses.Complete.Color,
                        '&:hover': { backgroundColor: RowStatuses.Complete.ColorHover },
                    },
                }}
                autoHeight
                slots={{
                    noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No serialized inbound items to scan
                        </Stack>
                    ),
                    noResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            Local filter returns no result
                        </Stack>
                    ),
                }}
            />
            {itemToAddSerialsTo && <AddSerialDialog item={itemToAddSerialsTo} onClose={handleDialogClose} />}
        </>
    );
};

export default AddSerialDatagrid;
