import { Box } from '@mui/material';
import AddSerialButton from './AddSerialButton';
import AddSerialDatagrid from './AddSerialDatagrid';
import AddSerialHeader from './AddSerialHeader';

const SerialElement = () => {
    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <AddSerialHeader />

            <AddSerialDatagrid />

            <AddSerialButton />
        </Box>
    );
};

export default SerialElement;
