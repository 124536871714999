import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { AtLeastOne } from '../../common/utils/typedCommonUtils';
import { GetReceiversRequest, SubmissionForm } from './receiversModels';

type ReceiverModeType = 'Edit' | 'Create' | 'View';

export interface ReceiversState {
    filter: GetReceiversRequest;
    receiverNumber: number;
    form: SubmissionForm;
    isDialogOpen: boolean;
    mode: ReceiverModeType;
}

const initialFilter: GetReceiversRequest = { IsPlayed: false, IsActive: true };

export const initialForm: SubmissionForm = {
    Active: true,
    Client: '',
    Project: '',
    Po: '',
    RecDate: dayjs().format(),
    VerDate: dayjs().format(),
    RecBy: '',
    Location: '',
    UnitType: '',
    Shipper: '',
    Carrier: '',
    Pack: '',
    Lading: '',
    StockTime: 0,
    LabelTime: 0,
    ShelfTime: 0,
    Palletcount: 0,
    ScDeliveryApt: true,
    ScPl: false,
    ScPlqty: false,
    ScCartonMark: false,
    ScBoxCondition: false,
    ScPalletCondition: false,
    Items: [],
    Comment: '',
    Played: false,
    Completed: false,
    RequestAdditionalSerialScans: false,
};

const initialState: ReceiversState = {
    filter: initialFilter,
    receiverNumber: 0,
    form: initialForm,
    isDialogOpen: false,
    mode: 'View',
};

const slice = createSlice({
    name: 'receivers',
    initialState,
    reducers: {
        setFilter: (state, { payload }: { payload: AtLeastOne<GetReceiversRequest> }) => {
            state.filter = { ...state.filter, ...payload };
        },
        resetFilter: state => {
            state.filter = initialFilter;
        },
        setReceiverNumber: (state, action: PayloadAction<number>) => {
            state.receiverNumber = action.payload;
        },
        setForm: (state, action: PayloadAction<SubmissionForm>) => {
            state.form = action.payload;
        },
        overrideForm: (state, action: PayloadAction<Partial<SubmissionForm>>) => {
            state.form = { ...state.form, ...action.payload };
        },
        resetForm: state => {
            state.form = initialForm;
        },
        resetAndOverrideForm: (state, action: PayloadAction<any>) => {
            state.form = { ...initialForm, ...action.payload };
        },
        setIsDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.isDialogOpen = action.payload;
        },
        setMode: (state, action: PayloadAction<ReceiverModeType>) => {
            state.mode = action.payload;
        },
    },
});

export const {
    setFilter,
    resetFilter,
    setReceiverNumber,
    setForm,
    resetForm,
    setIsDialogOpen,
    resetAndOverrideForm,
    setMode,
    overrideForm,
} = slice.actions;

export default slice.reducer;
