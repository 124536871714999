import { GET_SEAL_BATCH_ORDER_COUNTS_URL, GET_SEAL_BATCH_ORDERS_URL } from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import {
    GetBackOrderCountsRequest,
    GetBatchOrderCountsResponse,
    GetBatchOrderMonitorsRequest,
    GetBatchOrderMonitorsResponse,
} from './batchOrderMonitorModels';

export const batchOrderMonitorApi = baseApi.enhanceEndpoints({});

export default batchOrderMonitorApi.injectEndpoints({
    endpoints: builder => ({
        getBatchOrderCounts: builder.query<GetBatchOrderCountsResponse, GetBackOrderCountsRequest>({
            query: param => ({
                url: prependDreamUrl(GET_SEAL_BATCH_ORDER_COUNTS_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: !!param.MomCode ? param.MomCode : '',
                },
            }),
        }),
        getBatchOrders: builder.query<GetBatchOrderMonitorsResponse, GetBatchOrderMonitorsRequest>({
            query: param => ({
                url: prependDreamUrl(GET_SEAL_BATCH_ORDERS_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: !!param.MomCode ? param.MomCode : '',
                },
            }),
        }),
    }),
});
