import { Autocomplete, TextField } from '@mui/material';
import printerApi from '../printerApi';

const PrinterNameAutocomplete = ({
    printerName,
    handleInputChange,
}: {
    printerName: string;
    handleInputChange: (newPrinterName: string) => void;
}) => {
    const getAccessiblePrintersResponse = printerApi.endpoints.getAccessiblePrinters.useQuery();

    return (
        <Autocomplete
            disablePortal
            options={getAccessiblePrintersResponse.data || []}
            onChange={(event, newInputValue) => {
                handleInputChange(newInputValue || '');
            }}
            sx={{ flex: 2, margin: 1, mb: 3 }}
            renderInput={params => <TextField {...params} label="Printer Name" required />}
        />
    );
};

export default PrinterNameAutocomplete;
