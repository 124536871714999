import { useParams } from 'react-router';
import Page from '../../../../../../common/components/container/Page';
import { INBOUND_SLUG } from '../../../../../authentication/slugs';
import { breadcrumbNameMap } from '../../const';
import SerialElement from './SerialElement';

const Serial = () => {
    const routeParams: any = useParams();

    const resumeIdRoute = `/${INBOUND_SLUG}/resume/${routeParams.receiverId}`;
    const resumeIdBreadcrumbMap = { [resumeIdRoute]: routeParams.receiverId };

    return (
        <Page
            maxWidth="xl"
            title={'Add Serial to Inbound'}
            childrenElement={<SerialElement />}
            breadcrumbNameMap={{ ...breadcrumbNameMap, ...resumeIdBreadcrumbMap }}
        />
    );
};

export default Serial;
