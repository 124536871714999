import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { GetItemResponse, Item } from '../../../receivers/receiversModels';

interface WrappedAutoCompleteProps
    extends Omit<AutocompleteProps<Item, undefined, boolean | undefined, false>, 'options' | 'renderInput' | 'value'> {
    textFieldProps?: TextFieldProps;
    value: string;
    client: string;
    getItemResponse: TypedUseQueryStateResult<GetItemResponse, unknown, any>;
}

const getLabel = (input: Item) => {
    if (typeof input === 'string') {
        return input;
    }

    return `${input.Sku}: ${input.Description}`;
};

const arrowDropDownIcon = <ArrowDropDownIcon />;

const circularProgress = <CircularProgress color="inherit" size={20} />;

//V1 -> V2: Use user code as value
export default function SkuByUpcDropdown({
    textFieldProps,
    value,
    client,
    getItemResponse,
    ...props
}: WrappedAutoCompleteProps) {
    const itemMatchingControllValue = getItemResponse?.data?.Data.find(item => item.Sku === value) || undefined;

    return (
        <Autocomplete
            key={JSON.stringify(getItemResponse.data)}
            value={itemMatchingControllValue}
            options={getItemResponse?.data?.Data || []}
            getOptionLabel={getLabel}
            popupIcon={props.readOnly ? '' : arrowDropDownIcon}
            renderInput={params => <TextField {...params} {...textFieldProps} />}
            {...props}
        />
    );
}
