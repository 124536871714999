import { NoteAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircle from '@mui/icons-material/PlayCircle';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Backdrop, Box, Link, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { getReceiverPdfUrl } from '../../../../app/services/urlFunctions';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { INBOUND_SLUG } from '../../../authentication/slugs';
import { setMode } from '../../receiversSlice';
import PlayReceiverConfirmDialog from '../viewReceivers/PlayReceiverConfirmDialog';

const CreatedReceiverSpeedDial = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const mode = useAppSelector(state => state.receivers.mode);
    const { Number, Played, Items, Client, RequestAdditionalSerialScans } = useAppSelector(
        state => state.receivers.form
    );
    const dispatch = useAppDispatch();

    const [receiverToPlay, setReceiverToPlay] = React.useState<{ client: string; number: number } | undefined>(
        undefined
    );
    const navigate = useNavigate();

    const actions = [
        {
            icon: mode === 'Edit' ? <VisibilityIcon /> : <EditIcon />,
            name: mode === 'Edit' ? 'View' : 'Edit',
            handleClick: () => {
                const newMode = mode === 'Edit' ? 'View' : 'Edit';
                dispatch(setMode(newMode));
            },
            isDisabled: false,
        },
        {
            icon: <PlayCircle />,
            name: Played ? 'Already Played' : 'Play',
            handleClick: () => {
                if (!Played && Number) setReceiverToPlay({ client: Client, number: Number });
            },
            isDisabled: Played,
        },
        {
            icon: <NoteAdd />,
            name: RequestAdditionalSerialScans ? 'Add Serials' : 'Serials Already Added',
            handleClick: () => {
                navigate(`/${INBOUND_SLUG}/resume/${Number}/serial`);
            },
            isDisabled: false,
        },
        {
            icon:
                Client && Number ? (
                    <Link
                        href={getReceiverPdfUrl(Client, Number)}
                        color="inherit"
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <PrintIcon />
                    </Link>
                ) : (
                    <PrintIcon />
                ),
            name: 'Print',
            handleClick: () => {},
            isDisabled: false,
        },
    ];

    return Number ? (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="Work Order Actions"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={open ? handleClose : handleOpen}
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'blur') handleClose();
                }}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        // tooltipOpen
                        onClick={() => {
                            handleClose();
                            action.handleClick();
                        }}
                        sx={{
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            '&.Mui-disabled': {
                                pointerEvents: 'auto',
                            },
                            '&.MuiFab-root:hover': {
                                bgcolor: theme => (action.isDisabled ? theme.palette.action.disabledBackground : ''),
                            },
                        }}
                        FabProps={{ disabled: action.isDisabled }}
                    />
                ))}
            </SpeedDial>

            {receiverToPlay && (
                <PlayReceiverConfirmDialog
                    receiverToPlay={receiverToPlay}
                    handleCancel={() => {
                        setReceiverToPlay(undefined);
                    }}
                />
            )}
        </Box>
    ) : (
        <></>
    );
};

export default CreatedReceiverSpeedDial;
