import { Box, Container, Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import DialogAppBar from '../../../../common/components/Dialog/DialogAppBar';
import useNavigationWarning from '../../../../common/hooks/useNavigationWarning';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from '../../constants';
import { cleanTemplateData } from '../../util';
import workOrderApi from '../../workOrderApi';
import { AttachmentFile, WorkOrderData } from '../../workOrderModels';
import { setMode } from '../../workOrderSlice';
import EditWorkOrderSpeedDial from '../View/EditWorkOrderSpeedDial';
import { initialWorkOrderForm } from '../WorkOrderElement';
import WorkOrderCreateForm from './WorkOrderCreateForm';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//when coming from url link directly, no "isCreateDialogOpen" prop is provided
interface WorkOrderCreateDialogProps {
    isCreateDialogOpen?: boolean;
}

const areObjectsEqual = (a: WorkOrderData, b: WorkOrderData) => {
    const strA = JSON.stringify(simplifyFields(a));
    const strB = JSON.stringify(simplifyFields(b));

    const sortedA = sortStringAlphabetically(strA);
    const sortedB = sortStringAlphabetically(strB);

    return sortedA === sortedB;
};

const simplifyFields = (a: WorkOrderData): WorkOrderData => {
    return {
        ...a,
        AttachmentFiles: a.AttachmentFiles.map(file => {
            return { Name: file.Name, DeleteDateTime: file.DeleteDateTime } as AttachmentFile;
        }), //Checking for file content would be too expensive
        DateCreated: stripOutTimeFormDate(a?.DateCreated),
        DueDate: stripOutTimeFormDate(a?.DueDate),
        DateCompleted: stripOutTimeFormDate(a?.DateCompleted || ''),
        TemplateTypeId: UNASSIGNED_TEMPLATE_TYPE_ID,
    };
};

const sortStringAlphabetically = (str: string) => {
    return [...str].sort().join('');
};

const stripOutTimeFormDate = (date: string) => {
    return date?.substring(0, 10);
};

const WorkOrderCreateDialog: React.FC<WorkOrderCreateDialogProps> = ({ isCreateDialogOpen }) => {
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const tab = useAppSelector(state => state.workOrder.tab);
    const [isPromptOpen, setIsPromptOpen] = React.useState(false);

    const fullName = useAppSelector(state => state.authentication.fullName);

    const fixedInitialForm = {
        ...initialWorkOrderForm,
        RoRequester: fullName,
        TemplateOwner: fullName,
    } as WorkOrderData;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [shouldForceCloseDialog, setShouldForceCloseDialog] = React.useState(false);

    const form = useAppSelector(state => state.workOrder.form);

    const { workOrderId } = useParams<'workOrderId'>();
    const getDetailedWorkOrderResponse = workOrderApi.endpoints.getDetailedWorkOrder.useQuery(
        workOrderId ? { Id: Number(workOrderId) } : skipToken,
        { refetchOnMountOrArgChange: true }
    );

    useNotificationByResponse({
        isFetching: getDetailedWorkOrderResponse.isFetching,
        isError: getDetailedWorkOrderResponse.isError,
        errorContent: 'Error getting individual work order',
        isUninitialized: getDetailedWorkOrderResponse.isUninitialized,
    });

    const hasUserMadeChanges = (): boolean => {
        const isFromTemplate = form.TemplateTypeId !== UNASSIGNED_TEMPLATE_TYPE_ID;
        const isFromWorkOrder = !isFromTemplate;

        const isCreatingFromTemplate = mode === 'Create' && isFromTemplate;
        const isInTemplateTab = mode !== 'Create' && tab === 'Templates';
        const isEditingFromWorkOrder = mode !== 'Create' && isFromWorkOrder;

        const shouldCleanData = isCreatingFromTemplate;

        const fetchedWorkOrder = shouldCleanData
            ? cleanTemplateData(getDetailedWorkOrderResponse?.data || fixedInitialForm)
            : getDetailedWorkOrderResponse.data || fixedInitialForm;

        const shouldUseFetchedWorkOrder = isCreatingFromTemplate || isEditingFromWorkOrder || isInTemplateTab;

        const isCurrentFormEqualToInitialForm = shouldUseFetchedWorkOrder
            ? areObjectsEqual(fetchedWorkOrder, form)
            : areObjectsEqual(fixedInitialForm, form);

        return !isCurrentFormEqualToInitialForm;
    };

    const handleDialogClose = () => {
        if (hasUserMadeChanges()) setIsPromptOpen(true);
        else handleExitForm();
    };

    const [searchParams] = useSearchParams();

    const handleExitForm = () => {
        setIsPromptOpen(false);
        dispatch(setMode('View'));
        setShouldForceCloseDialog(true);
        setTimeout(() => {
            navigate({
                pathname: `/workOrder`,
                search: searchParams.toString(),
            });
        }, 100);
    };

    useNavigationWarning({
        shouldShowWarningOnNavigationChange:
            isCreateDialogOpen !== undefined
                ? isCreateDialogOpen && hasUserMadeChanges()
                : !shouldForceCloseDialog && hasUserMadeChanges(),
    });

    return (
        <Box>
            <Dialog
                fullScreen
                open={isCreateDialogOpen !== undefined ? isCreateDialogOpen : !shouldForceCloseDialog}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <DialogAppBar
                    handleClose={handleDialogClose}
                    title={`${mode} ${tab === 'Work Orders' ? 'Work Order' : 'Template'}`}
                />
                {mode !== 'Create' && <EditWorkOrderSpeedDial closeDialog={handleExitForm} />}

                <Container maxWidth={false}>
                    <WorkOrderCreateForm hasUserMadeChanges={hasUserMadeChanges()} />
                </Container>
            </Dialog>

            <ConfirmDialog
                open={isPromptOpen}
                handleCancel={() => {
                    setIsPromptOpen(false);
                }}
                handleConfirm={handleExitForm}
                title={'Unsaved Changes'}
                contents={['Exit without saving changes?']}
            />
        </Box>
    );
};

export default WorkOrderCreateDialog;
