import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

/*
Test cases:
    - negatives
    - decimals
    - toFixed
    - typing when entirety is highlighted
    - from zero 
    - from empty string 
*/

//TODO: extend textfield props
interface NumberTextfieldProps {
    value: number;
    onChange: (input: number) => void;
    toFixed?: number | undefined;
    isNegativeAllowed?: boolean;
    isDecimalAllowed?: boolean;
    textFieldProps?: TextFieldProps;
}

//takes in a number, and returns number
const NumberTextfieldV2 = ({
    isNegativeAllowed = false,
    isDecimalAllowed = false,
    toFixed = undefined,
    value,
    onChange,
    textFieldProps = {},
}: NumberTextfieldProps) => {
    const [currText, setCurrText] = React.useState(Number(value)?.toFixed(toFixed));

    const handleTextChange = (input: string) => {
        setCurrText(prevText => {
            const truncatedText = removeZerosFromStartOfText(prevText, input);

            if (!doesPassNegativeTest(truncatedText)) return prevText;
            if (!doesPassDecimalTest(truncatedText)) return prevText;

            return truncatedText;
        });
    };

    const finalizeValue = () => {
        const fixedText = fixStringToValidNumber(currText);
        onChange(Number(fixedText) || 0);
        setCurrText(fixedText);
    };

    const fixStringToValidNumber = (textToFix: string): string => {
        const roundedValue = Number(textToFix).toFixed(toFixed);
        const newValue = roundedValue === 'NaN' ? Number(0).toFixed(toFixed) : roundedValue;
        return newValue;
    };

    const removeZerosFromStartOfText = (prevText: string, input: string): string => {
        const prevNumber = Number(prevText);
        const isNegativeZero = prevNumber === 0 && input.startsWith('-');
        if (isNegativeZero) return input;

        const isPrevNumberZero = prevNumber === 0;
        const isUserOverridingFullText = !input.startsWith(prevText);
        const shouldClearPrevText = isPrevNumberZero && !isUserOverridingFullText;
        const truncatedText = shouldClearPrevText ? input.substring(prevText.length) : input;

        return truncatedText;
    };

    const doesPassNegativeTest = (input: string) => {
        const allowNegativePattern = /^[-]?[\.\d]*$/;
        const denyNegativePattern = /^[\.\d]*$/;
        const patternToUse = isNegativeAllowed ? allowNegativePattern : denyNegativePattern;
        return patternToUse.test(input);
    };

    const doesPassDecimalTest = (input: string) => {
        const allowDecimalPattern = toFixed ? new RegExp(`^[-]?[\\d]*[\\.]?[\\d]{0,${toFixed}}$`) : /^[-]?[\d]*[\.]?$/;
        const denyDecimalPattern = /^[-]?[\d]*$/;
        const patternToUse = isDecimalAllowed ? allowDecimalPattern : denyDecimalPattern;
        return patternToUse.test(input);
    };

    return (
        <TextField
            variant={textFieldProps?.variant}
            value={currText === '0' ? '' : currText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const input = event?.target?.value || '';
                handleTextChange(input);
            }}
            name={textFieldProps?.label?.toString() || ''}
            onBlur={finalizeValue}
            onKeyDownCapture={event => {
                if (event.key === 'Enter') {
                    finalizeValue();
                }
            }}
            {...textFieldProps}
        />
    );
};

export default NumberTextfieldV2;
