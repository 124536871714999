import Page from '../../../../../../common/components/container/Page';
import { INBOUND_SLUG } from '../../../../../authentication/slugs';
import DialogForm from '../../../form/viewForm/DialogForm';
import { breadcrumbNameMap } from '../../const';

const NewInbound = () => {
    return (
        <Page
            title={'Create New Inbound'}
            childrenElement={<DialogForm getCreateRedirectLink={id => `/${INBOUND_SLUG}/resume/${id}`} />}
            maxWidth="xl"
            breadcrumbNameMap={breadcrumbNameMap}
        />
    );
};

export default NewInbound;
