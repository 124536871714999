import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { REPORT_SEARCH_BY_OPTIONS } from '../constants';
import { GetRmaRequest } from '../rmaModels';

export const SearchByDropDown = React.memo(
    ({
        searchByDropDownValue,
        setSearchByDropDownValue,
        setSearchFilter,
    }: {
        searchByDropDownValue: string;
        setSearchByDropDownValue: (value: string) => void;
        setSearchFilter: (filter: GetRmaRequest | ((prevFilter: GetRmaRequest) => GetRmaRequest)) => void;
    }) => {
        const handleOnChange = (value: string) => {
            setSearchByDropDownValue(value);
        };

        return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="rma-search-menu-label">Search By</InputLabel>
                <Select
                    labelId="search-menu-label"
                    id="search-menu"
                    value={searchByDropDownValue}
                    onChange={event => {
                        handleOnChange(event.target.value);
                    }}
                    label="Search By"
                    size="small"
                >
                    {Object.entries(REPORT_SEARCH_BY_OPTIONS).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
);
