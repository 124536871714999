import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../../../app/store';
import useMutationResponse from '../../../../../../common/hooks/useMutationResponse';
import { isFeatureAccessible } from '../../../../../../common/utils/commonUtils';
import authenticationApi from '../../../../../authentication/authenticationApi';
import { setDialog } from '../../../../../notification/notificationSlice';
import { COMPLETE_INBOUND_BYPASS } from '../../../../../roles/permissionsList';
import receiversApi from '../../../../receiversApi';
import { isSerialDataHidden } from './constants';

const AddSerialButton = () => {
    const dispatch = useAppDispatch();
    const routeParams: any = useParams();

    const [isBypassRequirementsChecked, setIsBypassRequirementsChecked] = React.useState(false);
    const [bypassReason, setBypassReason] = React.useState('');

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    const getScannedSerialsResponse = receiversApi.endpoints.getAddSerialData.useQuery({
        ReceiverId: routeParams.receiverId,
        ShouldIncludeCompletedSerials: !getReceiverResponse?.data?.RequestAdditionalSerialScans,
    });

    const [
        completeSerialCachesTrigger,
        completeSerialCachesResponse,
    ] = receiversApi.endpoints.completeSerialCaches.useMutation();

    const handleComplete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isBypassRequirementsChecked) {
            completeSerialCaches();
            return;
        }

        const itemWithIncorrectSerialScanCount = getScannedSerialsResponse?.data
            ?.filter(item => !isSerialDataHidden(item))
            .find(item => item.CurrentScannedCount !== item.TotalSerialsToScan);

        if (!getReceiverResponse?.data?.RequestAdditionalSerialScans) {
            dispatch(setDialog({ content: 'Already completed' }));
            return;
        }

        if (itemWithIncorrectSerialScanCount) {
            dispatch(
                setDialog({
                    content: `Number of serials scanned does not match item quantity\n(Sku: ${itemWithIncorrectSerialScanCount.Sku}). \n\nIf serial scan count is correct, \nplease edit receiver item quantity to match.`,
                })
            );
            return;
        }

        completeSerialCaches();
    };

    const completeSerialCaches = () => {
        if (!routeParams.receiverId) return;

        completeSerialCachesTrigger({ ReceiverId: routeParams.receiverId, BypassReason: bypassReason });
    };

    useMutationResponse({
        response: completeSerialCachesResponse,
        errorMessage: 'Could not be completed',
        successMessage: 'Serial scans completed successfully',
    });

    const getUserPermissionsResponse = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions = getUserPermissionsResponse.data ? getUserPermissionsResponse.data : [];

    return (
        <Box sx={{ p: 1, flex: 1, m: 2 }}>
            <form onSubmit={handleComplete}>
                <Button variant="contained" type="submit" fullWidth>
                    Complete
                </Button>

                {isFeatureAccessible(userPermissions, COMPLETE_INBOUND_BYPASS) &&
                    getReceiverResponse?.data?.RequestAdditionalSerialScans && (
                        <Box sx={{ display: 'flex', my: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isBypassRequirementsChecked}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setIsBypassRequirementsChecked(event.target.checked);
                                        }}
                                        sx={{
                                            color: red['A400'],
                                            '&.Mui-checked': {
                                                color: red['A700'],
                                            },
                                        }}
                                    />
                                }
                                sx={{
                                    color: 'red',
                                    m: 1,
                                }}
                                label="Bypass requirements"
                            />

                            <TextField
                                label="Reason"
                                value={bypassReason}
                                onChange={e => setBypassReason(e.target.value)}
                                sx={{ flex: 1, mx: 1, display: isBypassRequirementsChecked ? '' : 'none' }}
                                variant="filled"
                                required={isBypassRequirementsChecked}
                            />
                        </Box>
                    )}
            </form>
        </Box>
    );
};

export default AddSerialButton;
