import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { RouteObject, useRoutes } from 'react-router-dom';
import './App.css';
import GlobalDialog from './common/components/Dialog/GlobalDialog';
import GlobalSnackbar from './common/components/Snackbar/GlobalSnackbar';
import GlobalSpinner from './common/components/Spinner/GlobalSpinner';
import NonIntrusiveSpinner from './common/components/Spinner/NonIntrusiveSpinner';
import authenticationApi from './features/authentication/authenticationApi';
import { getAllowedRoutes } from './features/authentication/Routes';
import AppBarNavigationWarningDialog from './features/notification/components/AppBarNaviationWarningDialog';

const App = () => {
    const getUserPermissionsResponse = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions = getUserPermissionsResponse.data ? getUserPermissionsResponse.data : [];

    const allowedRoutes = getAllowedRoutes(userPermissions);

    const appRoutes = useRoutes(allowedRoutes as RouteObject[]);

    const theme = createTheme({
        typography: {
            fontFamily: 'Montserrat',
        },
    }) as Theme;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <CssBaseline />
                    {appRoutes}

                    <GlobalDialog />

                    <GlobalSnackbar />

                    <GlobalSpinner />

                    <NonIntrusiveSpinner />

                    <AppBarNavigationWarningDialog />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
