import { Box, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import CompanyDropdownV3 from '../../../common/components/dropdown/CompanyDropdownV3';
import UserDropdownV2 from '../../../common/components/dropdown/UserDropdownV2';
import {
    INPUT_DATETIME_FORMAT,
    REPORT_SEARCH_BY_OPTIONS,
    RMA_REPORT_RESULTS_DEFAULT_COUNT,
    RMA_REPORT_RESULTS_MAX_COUNT,
} from '../constants';
import { GetRmaRequest } from '../rmaModels';
import { SearchByDropDown } from './SearchByDropDown';
import { StatusFilterDropDown } from './StatusFilterDropDown';
import { grey } from '@mui/material/colors';

export const RmaReportFilter = ({
    searchFilter,
    setSearchFilter,
    searchByDropDownValue,
    setSearchByDropDownValue,
}: {
    searchFilter: GetRmaRequest;
    setSearchFilter: (filter: GetRmaRequest | ((prevFilter: GetRmaRequest) => GetRmaRequest)) => void;
    searchByDropDownValue: string;
    setSearchByDropDownValue: (value: string) => void;
}) => {
    const theme = useTheme();
    const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: isSmallViewPort ? 'column' : 'row' }}>
                    <CompanyDropdownV3
                        value={searchFilter.MomCode || ''}
                        onChange={(e, company) => {
                            setSearchFilter(prevState => ({
                                ...prevState,
                                MomCode: company?.mom,
                            }));
                        }}
                        sx={{ flex: 2, m: 1 }}
                    />
                    <Box sx={{ flex: 2, m: 1 }}>
                        <StatusFilterDropDown searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 5,
                            border: '1px solid',
                            borderColor: grey['300'],
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            alignItems: 'center',
                            m: 1,
                        }}
                    >
                        <Box sx={{ flex: 2, mr: 0.5, ml: 1 }}>
                            <SearchByDropDown
                                searchByDropDownValue={searchByDropDownValue}
                                setSearchByDropDownValue={setSearchByDropDownValue}
                                setSearchFilter={setSearchFilter}
                            />
                        </Box>
                        <TextField
                            variant="outlined"
                            value={searchFilter?.Query || ''}
                            label={REPORT_SEARCH_BY_OPTIONS[searchByDropDownValue]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setSearchFilter(prevState => ({
                                    ...prevState,
                                    Query: newValue,
                                }));
                            }}
                            size="small"
                            sx={{ flex: 3, ml: 0.5, mr: 1 }}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isSmallViewPort ? 'column' : 'row' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={'Processed From'}
                            value={searchFilter?.FromProcessedDate ? dayjs(searchFilter?.FromProcessedDate) : null}
                            onChange={newValue => {
                                setSearchFilter(prevState => ({
                                    ...prevState,
                                    FromProcessedDate: newValue?.format(INPUT_DATETIME_FORMAT),
                                    ResultsCountLimit:
                                        !!prevState.ToProcessedDate && !!newValue
                                            ? RMA_REPORT_RESULTS_MAX_COUNT
                                            : RMA_REPORT_RESULTS_DEFAULT_COUNT,
                                }));
                            }}
                            sx={{ width: '100%', m: 1, flex: 1 }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={'Processed To'}
                            value={searchFilter?.ToProcessedDate ? dayjs(searchFilter?.ToProcessedDate) : null}
                            onChange={newValue => {
                                setSearchFilter(prevState => ({
                                    ...prevState,
                                    ToProcessedDate: newValue
                                        ? newValue.endOf('day').format(INPUT_DATETIME_FORMAT)
                                        : null,
                                    ResultsCountLimit:
                                        !!prevState.FromProcessedDate && !!newValue
                                            ? RMA_REPORT_RESULTS_MAX_COUNT
                                            : RMA_REPORT_RESULTS_DEFAULT_COUNT,
                                }));
                            }}
                            sx={{ width: '100%', m: 1, flex: 1 }}
                        />
                    </LocalizationProvider>
                    <UserDropdownV2
                        onChange={(event, newValue) => {
                            setSearchFilter(prevState => {
                                return { ...prevState, ProcessedBy: newValue?.code };
                            });
                        }}
                        textFieldProps={{ label: 'Processed By' }}
                        value={searchFilter?.ProcessedBy || ''}
                        sx={{ flex: 1, m: 1 }}
                    />
                </Box>
            </Box>
        </Box>
    );
};
