import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import { setDialog } from '../../../notification/notificationSlice';
import { parseInitialQCScan } from '../../../qualityControl/util/handleInitialScan';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import rmaApi from '../../rmaApi';
import { Disposition, Item, ReceivedItem } from '../../rmaModels';
import { submitOnEnterKeyDown } from '../../utilities';
import { AddFromOriginalOrderDisplay } from './AddFromOriginalOrderDisplay';
import { ChooseSkuDialog } from './ChooseSkuDialog';

const unknownItemLabel = 'UNKNOWN ITEM';

export const AddItemDisplay = React.memo(
    ({
        addingItem,
        showErrors = { sku: false, qty: false, triedSubmitting: false },
        setAddingItem,
    }: {
        addingItem: ReceivedItem;
        showErrors?: { sku: boolean; qty: boolean; triedSubmitting: boolean };
        setAddingItem: (addingItem: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
    }) => {
        const dispatch = useDispatch();
        const rma = useAppSelector(state => state.rma.rma);
        const stockQueryTextfieldRef = useRef<HTMLInputElement>(null);
        const hasOriginalOrderItems = rma?.OriginalOrderDetails && rma?.OriginalOrderDetails?.Items?.length > 0;
        const skuTextFieldLabel = hasOriginalOrderItems ? 'SCAN UPC/SKU or SELECT from  above' : 'SCAN UPC or SKU';
        const [noSkuDispositions, setNoSkuDispositions] = React.useState<Disposition[]>([]);
        const noSkuDispositionsResponse = rmaApi.endpoints.getDispositionsForSku.useQuery(
            rma?.Client
                ? {
                      momCode: rma.Client,
                      sku: '',
                  }
                : skipToken
        );

        const [selectSkuDialog, setSelectSkuDialog] = React.useState(false);
        const [stockQuery, setStockQuery] = React.useState(addingItem.Sku);
        const [queriedStocks, setQueriedStocks] = React.useState<Item[]>([]);
        const [skuFoundMsg, setSkuFoundMsg] = React.useState('');
        const [triggerGetStock, stockResponse] = rmaApi.endpoints.getStock.useLazyQuery();

        useNotificationByResponse({
            isFetching: stockResponse.isFetching,
            isError: stockResponse.isError,
            errorContent: 'Could not correctly retrieve data, please try again later.',
            isUninitialized: stockResponse.isUninitialized,
        });

        useNotificationByResponse({
            isFetching: noSkuDispositionsResponse.isFetching,
            isError: noSkuDispositionsResponse.isError,
            errorContent: 'Could not get dispositions for no sku items',
            isUninitialized: noSkuDispositionsResponse.isUninitialized,
        });

        React.useEffect(() => {
            if (!!noSkuDispositionsResponse?.data) {
                setNoSkuDispositions(noSkuDispositionsResponse.data);
            }
        }, [noSkuDispositionsResponse]);

        const submitStockQuery = (query: string | null | undefined) => {
            if (!!rma?.Client && !!query) {
                const parsedSku =
                    parseInitialQCScan(rma.Client, query).sku || parseInitialQCScan(rma.Client, query).upc;
                if (!!parsedSku && parsedSku != addingItem.Sku) {
                    triggerGetStock({ momCode: rma?.Client, stockQuery: parsedSku });
                    setStockQuery(parsedSku);
                }
            }
        };

        const handleSubmitStockQuery = () => {
            submitStockQuery(stockQuery);
        };

        const handleSkusFound = (stocks: Item[]) => {
            if (stocks?.length == 1) {
                const stock = stocks[0];
                setAddingItem((prevItem: ReceivedItem) => ({
                    ...prevItem,
                    Upc: stock.Upc,
                    Sku: stock.Sku,
                    UnknownItem: !stock.Sku,
                    IsSerialized: stock.IsSerialized,
                    AllowSerialDup: stock.AllowSerialDup,
                    SerialVerify: stock.SerialVerify,
                    Description: stock.Description,
                    ValidDispositions: stock.ValidDispositions,
                    ExpectedDisposition: stock.ExpectedDisposition,
                }));
                setSkuFoundMsg(`ITEM: ${stock.Description}`);
            } else if (stocks?.length > 1) {
                setQueriedStocks(stocks);
                setSelectSkuDialog(true);
            }
        };

        const handleSkuFromOrderSelect = (sku: string | null | undefined) => {
            if (!sku) return;
            handleUnknownChange(false);
            setStockQuery(sku);
            handleQtyChange('1');
            submitStockQuery(stockQuery);
        };

        const getSkuErrorMessage = () => {
            if (!showErrors.sku || !showErrors.triedSubmitting) {
                return '';
            }

            if (!stockQuery) {
                return `Please enter a UPC/SKU or select ${unknownItemLabel}`;
            } else {
                return 'Press ENTER to check if SKU exists.';
            }
        };

        const clearAndFocusUpcSkuTextfield = () => {
            setStockQuery('');
            textFieldFocus({ textFieldRef: stockQueryTextfieldRef });
        };

        const handleQtyChange = (value: string) => {
            const newValue = parseInt(value, 10);
            const cleanedValue = isNaN(newValue) ? 1 : newValue;
            const minValue = 1;
            setAddingItem((prevItem: ReceivedItem) => ({
                ...prevItem,
                Quantity: minValue >= cleanedValue ? minValue : cleanedValue,
            }));
        };

        const handleUnknownChange = (value: boolean) => {
            setStockQuery('');
            setSkuFoundMsg('');
            setAddingItem((prevItem: ReceivedItem) => ({
                ...prevItem,
                UnknownItem: value,
                Sku: value ? '' : prevItem.Sku,
                Upc: value ? '' : prevItem.Upc,
                IsSerialized: value ? true : prevItem.IsSerialized,
                SerialVerify: value ? null : prevItem.SerialVerify,
                AllowSerialDup: value ? false : prevItem.AllowSerialDup,
                ValidDispositions: value ? noSkuDispositions : [],
                ExpectedDisposition: value && !!noSkuDispositions ? noSkuDispositions[0] : prevItem.ExpectedDisposition,
            }));
        };

        React.useEffect(() => {
            if (!stockResponse.isUninitialized && !stockResponse.isFetching) {
                if (stockResponse.data?.Success && stockResponse.data?.Data?.length > 0) {
                    handleSkusFound(stockResponse.data.Data);
                } else {
                    dispatch(
                        setDialog({
                            title: 'ITEM NOT FOUND',
                            content: 'UPC/SKU does not appear to be valid for this client.',
                            color: red['A100'],
                        })
                    );
                    clearAndFocusUpcSkuTextfield();
                }
            }
        }, [stockResponse]);

        return (
            <Box>
                <ChooseSkuDialog
                    stocks={queriedStocks}
                    setAddingItem={setAddingItem}
                    setQuery={setStockQuery}
                    handleOpenDialog={setSelectSkuDialog}
                    showDialog={selectSkuDialog}
                />
                {hasOriginalOrderItems && (
                    <AddFromOriginalOrderDisplay
                        setSku={handleSkuFromOrderSelect}
                        submitStockQuery={submitStockQuery}
                    />
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3, mr: 2 }}>
                        <TextField
                            inputRef={stockQueryTextfieldRef}
                            error={showErrors.sku && showErrors.triedSubmitting}
                            value={stockQuery}
                            InputLabelProps={{ shrink: !!stockQuery }}
                            autoFocus
                            helperText={getSkuErrorMessage()}
                            id="upc-or-sku"
                            label={skuTextFieldLabel}
                            required={!addingItem.UnknownItem}
                            disabled={addingItem.UnknownItem}
                            variant="standard"
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                submitOnEnterKeyDown(event, handleSubmitStockQuery);
                            }}
                            onChange={event => {
                                setStockQuery(event.target.value.trim());
                                setSkuFoundMsg('');
                            }}
                        />
                        <Typography color="success.main">{skuFoundMsg}</Typography>
                        <FormControlLabel
                            label={unknownItemLabel}
                            sx={{ flex: 2 }}
                            control={
                                <Checkbox
                                    checked={addingItem.UnknownItem}
                                    onChange={event => handleUnknownChange(event.target.checked)}
                                />
                            }
                        />
                    </Box>
                    <TextField
                        value={addingItem.Quantity}
                        type="number"
                        id="recieveQty"
                        label="ENTER QTY"
                        InputLabelProps={{ shrink: addingItem.Quantity != null }}
                        error={showErrors.qty && showErrors.triedSubmitting}
                        helperText={showErrors.qty && showErrors.triedSubmitting ? 'Must be at least 1' : ''}
                        sx={{ flex: 1 }}
                        variant="standard"
                        required
                        onChange={event => {
                            handleQtyChange(event.target.value);
                        }}
                    />
                </Box>
            </Box>
        );
    }
);
