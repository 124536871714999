import {
    DREAM_URL,
    GET_RECEIVERS_CONFIG_URL,
    GET_RECEIVERS_HISTORY_URL,
    GET_RECEIVERS_INBOUNDS_URL,
    GET_RECEIVERS_ITEM_URL,
    GET_RECEIVERS_STOCKS_URL,
    GET_SEAL_RECEIVERS_URL,
    PLAY_RECEIVERS_URL,
    POST_RECEIVERS_FORM_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { DreamResponse } from '../tracking/trackingModels';
import {
    CompleteSerialCachesRequest,
    ConfigData,
    CreateReceiverResponse,
    DeleteSerialCachesRequest,
    GetInboundRequest,
    GetItemRequest,
    GetItemResponse,
    GetReceiversRequest,
    GetSerialCacheRequest,
    GetSerialDataRequest,
    History,
    Inbound,
    PlayReceiverPayload,
    PostSerialCachesRequest,
    PostSerialCachesResponse,
    SerialData,
    Stock,
    SubmissionForm,
    SubmissionFormItem,
} from './receiversModels';

const apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['receiversDataGrid', 'stock', 'serialCache', 'serialCacheHeader'],
});

const STOCKS_CACHE_LIFETIME_IN_SECS = 360;

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getReceivers: builder.query<SubmissionForm[], GetReceiversRequest>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_RECEIVERS_URL}`),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: param?.MomCode || '',
                },
            }),
            providesTags: ['receiversDataGrid'],
        }),

        getReceiver: builder.query<SubmissionForm, number>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_RECEIVERS_URL}/${param}`),
                method: 'GET',
            }),
            providesTags: ['receiversDataGrid'],
        }),

        getReceiverItem: builder.query<SubmissionFormItem[], number>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_RECEIVERS_URL}/${param}/items`),
                method: 'GET',
            }),
            providesTags: ['receiversDataGrid'],
        }),

        getReceiversConfig: builder.query<ConfigData, string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_CONFIG_URL}/${momCode}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),

        getReceiverInbounds: builder.query<Inbound[], string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_INBOUNDS_URL}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),

        getReceiverInbound: builder.query<Inbound, GetInboundRequest>({
            query: request => ({
                url: prependDreamUrl(`${GET_RECEIVERS_INBOUNDS_URL}/${request.poReferenceNumber}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: request.momCode,
                },
            }),
        }),

        getReceiverStocks: builder.query<SubmissionFormItem[], string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_STOCKS_URL}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
            providesTags: ['stock'],
            keepUnusedDataFor: STOCKS_CACHE_LIFETIME_IN_SECS,
            transformResponse: (rawResult: Stock[]): SubmissionFormItem[] => {
                return rawResult.map(stock => {
                    return {
                        Item: stock.Sku,
                        Upc: stock.Upc,
                        Descript: stock.Description,
                        RecCrtncount: 0,
                        RecCaseqty: 0,
                        RecPartial: 0,
                        Notes: '',
                        LineId: null,
                        RecQty: 0,
                        PlQty: 0,
                        Available: stock.Available,
                        CurrentScannedCount: 0,
                        TotalSerialsToScan: 0,
                    };
                });
            },
        }),

        getItem: builder.query<GetItemResponse, GetItemRequest>({
            query: param => ({
                url: prependDreamUrl(`${GET_RECEIVERS_ITEM_URL}`),
                params: {
                    Upc: param?.Upc,
                    Sku: param?.Sku,
                },
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.MomCode,
                },
            }),
        }),

        getReceiversHistory: builder.query<History[], { momCode: string; receiverNumber: number }>({
            query: param => ({
                url: prependDreamUrl(`${GET_RECEIVERS_HISTORY_URL}/${param.receiverNumber}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            providesTags: ['receiversDataGrid'],
        }),

        playReceiver: builder.mutation<DreamResponse, Partial<{ momCode: string; body: PlayReceiverPayload }>>({
            query: param => ({
                url: prependDreamUrl(`${PLAY_RECEIVERS_URL}`),
                method: 'PUT',
                body: param.body,
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            invalidatesTags: ['receiversDataGrid', 'stock'],
        }),

        updateReceiver: builder.mutation<CreateReceiverResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: prependDreamUrl(`${POST_RECEIVERS_FORM_URL}`),
                method: 'PUT',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),

        createReceiver: builder.mutation<CreateReceiverResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: prependDreamUrl(`${POST_RECEIVERS_FORM_URL}`),
                method: 'POST',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),

        getAddSerialData: builder.query<SerialData[], GetSerialDataRequest>({
            query: params => ({
                url: prependDreamUrl(`api/seal/receivers/${params.ReceiverId}/serialData`),
                method: 'GET',
                params: {
                    ShouldIncludeCompletedSerials: params.ShouldIncludeCompletedSerials,
                },
            }),
            providesTags: ['serialCacheHeader', 'receiversDataGrid'],
        }),

        getSerialCaches: builder.query<string[], GetSerialCacheRequest>({
            query: param => ({
                url: prependDreamUrl(`api/seal/receivers/serialCache`),
                method: 'GET',
                params: param,
            }),
            providesTags: (response, error, request) => [{ type: 'serialCache', id: request.ReceiverDataId }],
        }),

        deleteSerialCaches: builder.mutation<void, DeleteSerialCachesRequest>({
            query: body => ({
                url: prependDreamUrl(`api/seal/receivers/serialCache`),
                method: 'DELETE',
                body,
            }),
            invalidatesTags: (response, error, request) => [{ type: 'serialCache', id: request.ReceiverDataId }],
        }),

        postSerialCaches: builder.mutation<PostSerialCachesResponse, PostSerialCachesRequest>({
            query: body => ({
                url: prependDreamUrl(`api/seal/receivers/serialCache`),
                method: 'POST',
                body,
            }),
            invalidatesTags: (response, error, request) => [{ type: 'serialCache', id: request.ReceiverDataId }],
        }),

        completeSerialCaches: builder.mutation<void, CompleteSerialCachesRequest>({
            query: request => ({
                url: prependDreamUrl(`api/seal/receivers/complete`),
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['serialCache', 'serialCacheHeader', 'receiversDataGrid'],
        }),
    }),
});
