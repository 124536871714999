import { Box } from '@mui/material';
import { LandingPageDisplay } from './LandingPageDisplay';

const ProcessRmaElement = () => {
    return (
        <Box>
            <LandingPageDisplay />
        </Box>
    );
};

export default ProcessRmaElement;
