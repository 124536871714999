import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useAppDispatch } from '../../../../../app/store';
import Page from '../../../../../common/components/container/Page';
import useNotificationByQueryResponse from '../../../../../common/hooks/useNotificationByQueryResponse';
import { hoursToMins } from '../../../../../common/utils/typedCommonUtils';
import { INBOUND_SLUG } from '../../../../authentication/slugs';
import receiversApi from '../../../receiversApi';
import { SubmissionForm } from '../../../receiversModels';
import { setForm, setMode } from '../../../receiversSlice';
import DialogForm from '../../form/viewForm/DialogForm';
import { breadcrumbNameMap } from '../const';

const ResumeId = () => {
    const routeParams: any = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const routeBase = location.pathname.includes('inbound') ? 'Inbound' : 'Receivers';

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    React.useEffect(() => {
        if (getReceiverResponse?.data) {
            const fixedForm = fixForm(getReceiverResponse?.data);
            dispatch(setForm(fixedForm));
        }

        dispatch(setMode('View'));
    }, [getReceiverResponse.isFetching]);

    useNotificationByQueryResponse({
        response: getReceiverResponse,
        errorMessage: 'Could not get receiver data',
    });

    const fixForm = (brokenForm: SubmissionForm): SubmissionForm => {
        return {
            ...brokenForm,
            StockTime: hoursToMins(brokenForm.StockTime),
            LabelTime: hoursToMins(brokenForm.LabelTime),
            ShelfTime: hoursToMins(brokenForm.ShelfTime),
        } as SubmissionForm;
    };

    return (
        <Page
            maxWidth="xl"
            title={routeBase}
            childrenElement={<DialogForm getCreateRedirectLink={id => `/${INBOUND_SLUG}/resume/${id}`} />}
            breadcrumbNameMap={breadcrumbNameMap}
        />
    );
};

export default ResumeId;
