import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { SerialScan, SerialScanStatus } from '../../../../../receiversModels';
import { ScanSerialRowColor } from '../constants';
import SerialTableHeader from './SerialTableHeader';

const SerialTable = ({
    allSerials,
    handleDelete,
    allowDelete,
}: {
    allSerials: SerialScan[];
    handleDelete: (serial: SerialScan[]) => void;
    allowDelete: boolean;
}) => {
    const getScanSerialRowColor = (status: SerialScanStatus): string => {
        if (status === 'Synced') return ScanSerialRowColor.Synced.Color;
        else if (status === 'Rejected') return ScanSerialRowColor.Rejected.Color;
        else if (status === 'Pending') return ScanSerialRowColor.Pending.Color;

        return '';
    };

    const [selectedSerials, setSelectedSerials] = React.useState<SerialScan[]>([]);
    const numSelected = selectedSerials.length;
    const rowCount = allSerials.length;

    const onSelectAllClick = () => {
        if (rowCount === numSelected) setSelectedSerials([]);
        else setSelectedSerials([...allSerials]);
    };

    const toggleSerialSelect = (row: SerialScan) => {
        setSelectedSerials(prev =>
            prev.find(p => p.Serial === row.Serial)
                ? selectedSerials.filter(s => s.Serial !== row.Serial)
                : [...selectedSerials, { ...row }]
        );
    };

    return (
        <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} elevation={5}>
            <TableContainer>
                <Table size="small" sx={{ maxHeight: '80%', overflow: 'auto', flex: 1 }}>
                    <TableHead>
                        <TableRow>
                            {allowDelete && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={rowCount > 0 && numSelected === rowCount}
                                        onChange={onSelectAllClick}
                                    />
                                </TableCell>
                            )}
                            <TableCell></TableCell>
                            <TableCell>Serial</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allSerials.map((row, index) => (
                            <TableRow
                                key={row.Serial + row.Status}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: getScanSerialRowColor(row.Status),
                                }}
                                onClick={() => {
                                    toggleSerialSelect(row);
                                }}
                            >
                                {allowDelete && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={!!selectedSerials.find(r => r.Serial === row.Serial)}
                                        />
                                    </TableCell>
                                )}
                                <TableCell component="th" scope="row">
                                    {index + 1}.
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Serial}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Tooltip title={row.Status}>{row.Icon}</Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {allowDelete && numSelected >= 1 && (
                <SerialTableHeader
                    numSelected={numSelected}
                    handleDeleteSelected={() => {
                        handleDelete(selectedSerials);
                        setSelectedSerials([]);
                    }}
                />
            )}
        </Paper>
    );
};
export default React.memo(SerialTable);
