import { Box } from '@mui/material';
import React from 'react';
import { AsnFilterModel, Inbound } from '../../receiversModels';
import AsnDataGrid from './AsnDataGrid';
import AsnFilter from './AsnFilter';

const ViewAsns = ({ shouldNavigateToReceiversPage }: { shouldNavigateToReceiversPage: boolean }) => {
    const [asnFilter, setAsnFilter] = React.useState<AsnFilterModel>({ momCode: null });

    return (
        <Box sx={{ backgroundColor: 'white', p: 2 }}>
            <AsnFilter setAsnFilter={setAsnFilter} />
            <AsnDataGrid asnFilter={asnFilter} shouldNavigateToReceiversPage={shouldNavigateToReceiversPage} />
        </Box>
    );
};

export default ViewAsns;
