import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import useMutationResponse from '../../../../common/hooks/useMutationResponse';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import receiversApi from '../../receiversApi';
import { PlayReceiverPayload, PlayReceiverPayloadItem } from '../../receiversModels';

const PlayReceiverConfirmDialog = ({
    receiverToPlay,
    handleCancel,
}: {
    receiverToPlay: { client: string; number: number };
    handleCancel: () => void;
}) => {
    const [triggerPlayReceivers, playReceiversResponse] = receiversApi.endpoints.playReceiver.useMutation();
    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(receiverToPlay.number);

    useNotificationByQueryResponse({
        response: getReceiverResponse,
        errorMessage: 'Could not get data to play receiver',
    });

    useMutationResponse({
        response: playReceiversResponse,
    });

    const handlePlayReceiverConfirmationButtonClicked = () => {
        const items = getReceiverResponse.data?.Items?.map(
            (item): PlayReceiverPayloadItem => {
                return { Id: item.Id || -1, Sku: item.Item, RecQty: item.RecQty };
            }
        );
        const result: PlayReceiverPayload = {
            MomCode: receiverToPlay.client,
            RecNumber: receiverToPlay.number || 0,
            Items: items || [],
        };

        triggerPlayReceivers({ momCode: result.MomCode, body: result });
        handleCancel();
    };

    return (
        <ConfirmDialog
            open={!!receiverToPlay}
            handleCancel={handleCancel}
            handleConfirm={handlePlayReceiverConfirmationButtonClicked}
            title={`Starting Receiver Play #${receiverToPlay.number}`}
            contents={[
                getReceiverResponse?.data?.RequestAdditionalSerialScans
                    ? 'This receiver is missing serial scans and should not be played.'
                    : '',
                `Playing a receiver is NOT reversible.`,
                `This can take several minutes. You can view progress by refreshing the receiver's page.`,
                ``,
            ]}
        />
    );
};

export default PlayReceiverConfirmDialog;
