import { Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog, setSnackbar } from '../../../notification/notificationSlice';
import rmaApi from '../../rmaApi';
import { setRma } from '../../rmaSlice';
import { hasError } from '../../utilities';
import useQueryResponse from '../../../../common/hooks/useQueryResponse';

export const ResubmitRmaHistoryDisplay = () => {
    const dispatch = useDispatch();
    const rma = useAppSelector(state => state.rma.rma);
    const { username } = useAppSelector(state => state.authentication);

    const [triggerPostCreateHistory, postCreateHistoryResponse] = rmaApi.endpoints.postCreateHistory.useMutation();
    const [triggerGetRma, rmaResponse] = rmaApi.endpoints.getRmaById.useLazyQuery();

    const submitCreateHistoryHandler = () => {
        if (!rma?.Client || !rma?.Id) {
            return;
        }
        triggerPostCreateHistory({
            rma: rma,
            username: username,
            momCode: rma?.Client,
        });
    };

    useNotificationByMutationResponseV2({
        response: postCreateHistoryResponse,
    });

    useQueryResponse({
        response: rmaResponse,
        onSuccess() {
            if (!!rmaResponse.data && rmaResponse.data.Data.length > 0) {
                if (!rmaResponse.data?.Data) {
                    return;
                }
                dispatch(setRma(rmaResponse.data?.Data[0]));
            }
        },
    });

    React.useEffect(() => {
        if (!postCreateHistoryResponse.isUninitialized && !postCreateHistoryResponse.isLoading) {
            if (postCreateHistoryResponse.data?.Success) {
                triggerGetRma({ momCode: rma?.Client, rmaId: rma?.Id });
                dispatch(
                    setSnackbar({
                        content: 'RMA Successfully Resubmitted',
                    })
                );
            } else {
                dispatch(
                    setDialog({
                        content: postCreateHistoryResponse.data?.Error || 'Error creating submitting RMA.',
                        title: `RMA Submission Error`,
                    })
                );
            }
        }
    }, [postCreateHistoryResponse]);

    if (!!rma && (!hasError(rma.Status) || !!rma.ProcessedHistory?.Id)) {
        return <React.Fragment />;
    }

    return (
        <Box sx={{ display: 'flex', p: 2 }}>
            <Button onClick={submitCreateHistoryHandler} variant="contained" sx={{ flex: 1 }}>
                RESUBMIT RMA
            </Button>
        </Box>
    );
};
