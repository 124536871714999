import { Autocomplete, Divider, Paper, TextField } from '@mui/material';
import React from 'react';
import InvalidLengthForm from './BAK/InvalidLengthForm';

interface RelabelProjectOption {
    ProjectName: string;
    RenderedElement: React.ReactNode;
}

const RELABEL_PROJECT_OPTIONS: RelabelProjectOption[] = [
    {
        ProjectName: 'BAK Invalid Length',
        RenderedElement: <InvalidLengthForm />,
    },
];

const RelabelElement = () => {
    const [projectType, setProjectType] = React.useState<RelabelProjectOption>(RELABEL_PROJECT_OPTIONS[0]);

    return (
        <Paper sx={{ p: 2 }}>
            <Autocomplete
                value={projectType}
                options={RELABEL_PROJECT_OPTIONS}
                getOptionLabel={option => option.ProjectName}
                onChange={(event, option) => setProjectType(option || RELABEL_PROJECT_OPTIONS[0])}
                renderInput={params => <TextField label={'Project'} {...params} />}
            />

            <Divider sx={{ m: 5 }} />

            {projectType?.RenderedElement}
        </Paper>
    );
};

export default RelabelElement;
