import { Box, Container, Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import DialogAppBar from '../../../../common/components/Dialog/DialogAppBar';
import useNavigationWarning from '../../../../common/hooks/useNavigationWarning';
import { resetForm, setIsDialogOpen, setMode } from '../../receiversSlice';
import DialogForm from '../form/viewForm/DialogForm';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReceiversCreateDialog = ({ hasAsn }: { hasAsn: boolean }) => {
    const dispatch = useDispatch();
    const isDialogOpen = useAppSelector(state => state.receivers.isDialogOpen);
    const mode = useAppSelector(state => state.receivers.mode);

    const [isPromptOpen, setIsPromptOpen] = React.useState(false);

    const handleDialogClose = () => {
        if (mode === 'Edit') {
            dispatch(setMode('View'));
            return;
        }

        setIsPromptOpen(true);
    };

    const handleExitForm = () => {
        setIsPromptOpen(false);
        dispatch(resetForm());
        dispatch(setIsDialogOpen(false));
    };

    useNavigationWarning({ shouldShowWarningOnNavigationChange: isDialogOpen });

    return (
        <Box>
            <Dialog
                fullScreen
                open={isDialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
                // keepMounted
            >
                <DialogAppBar handleClose={handleDialogClose} title={`${mode} Receiver`} />

                <Container maxWidth={'xl'}>
                    <DialogForm hasAsn={hasAsn} handleExitForm={handleExitForm} />
                </Container>
            </Dialog>

            <ConfirmDialog
                open={isPromptOpen}
                handleCancel={() => {
                    setIsPromptOpen(false);
                }}
                handleConfirm={handleExitForm}
                title={'Possibly Unsaved Changes'}
                contents={['Exit without saving changes?']}
            />
        </Box>
    );
};

export default ReceiversCreateDialog;
