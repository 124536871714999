import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { COMPLETE_STATUS_ID, ERROR_STATUS_ID } from '../constants';
import { GetRmaRequest } from '../rmaModels';

const STATUS_OPTIONS = { ALL: 0, COMPLETE: COMPLETE_STATUS_ID, ERROR: ERROR_STATUS_ID };

export const StatusFilterDropDown = React.memo(
    ({
        searchFilter,
        setSearchFilter,
    }: {
        searchFilter: GetRmaRequest;
        setSearchFilter: (filter: GetRmaRequest | ((prevFilter: GetRmaRequest) => GetRmaRequest)) => void;
    }) => {
        const handleOnChange = (value: number | null) => {
            setSearchFilter({ ...searchFilter, Status: value });
        };

        return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="staus-filter-menu-label">Status</InputLabel>
                <Select
                    labelId="filter-menu-label"
                    id="filter-menu"
                    value={searchFilter.Status}
                    defaultValue={0}
                    onChange={event => {
                        const statusId = (event.target.value as number) || 0;
                        handleOnChange(statusId);
                    }}
                    label="Status"
                >
                    {Object.entries(STATUS_OPTIONS).map(([label, value]) => (
                        <MenuItem key={label} value={String(value)}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
);
