import { skipToken } from '@reduxjs/toolkit/query';
import ConfirmDialog from '../../../../../common/components/Dialog/ConfirmDialog';
import receiversApi from '../../../receiversApi';
import { SubmissionFormItem } from '../../../receiversModels';

const DeleteItemConfirmDialog = ({
    handleConfirm,
    handleCancel,
    item,
}: {
    handleConfirm: () => void;
    handleCancel: () => void;
    item: SubmissionFormItem | undefined;
}) => {
    const getSerialCachesResponse = receiversApi.endpoints.getSerialCaches.useQuery(
        item?.Id
            ? {
                  ReceiverDataId: item.Id,
                  ShouldIncludeCompletedSerials: false,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true }
    );

    const ongoinSerialsMessage = () => {
        if (getSerialCachesResponse?.data && getSerialCachesResponse?.data?.length > 0) {
            return 'This item has unsynced captured serials. Deleting this item will permanently delete those serials.';
        }

        return '';
    };

    return (
        <ConfirmDialog
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            open={!!item}
            contents={[ongoinSerialsMessage(), `Are you sure you want to delete ${item?.Item}?`]}
            title="Confirm Item Delete"
        />
    );
};

export default DeleteItemConfirmDialog;
