import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ConfirmDialog from '../../../../../../../common/components/Dialog/ConfirmDialog';

const SerialTableHeader = ({
    numSelected,
    handleDeleteSelected,
}: {
    numSelected: number;
    handleDeleteSelected: () => void;
}) => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

    return (
        <>
            <Toolbar>
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
                <Tooltip title="Delete">
                    <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <ConfirmDialog
                open={isDeleteDialogOpen}
                handleCancel={() => {
                    setIsDeleteDialogOpen(false);
                }}
                handleConfirm={handleDeleteSelected}
                title="Confirm Delete"
                contents={[`Are you sure you want to delete ${numSelected} serials?`]}
            />
        </>
    );
};

export default SerialTableHeader;
