import { Box, DialogContentText, Paper } from '@mui/material';
import { DUPLICATE_SERIAL_COLORS, ScanSerialRowColor } from '../constants';

const GET_DUPLICATE_SERIAL_BACKGROUND = () => {
    let result = `linear-gradient(to right, `;

    const colors = DUPLICATE_SERIAL_COLORS.map((color, index) => {
        const startPosition = index * (100 / DUPLICATE_SERIAL_COLORS.length);
        const endPosition = (index + 1) * (100 / DUPLICATE_SERIAL_COLORS.length);
        return `${color} ${startPosition}% ${endPosition}%`;
    }).join(', ');

    result += colors + ')';

    return result;
};

const SerialTextfieldLegend = () => {
    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Paper elevation={5} sx={{ p: 1, mt: 2 }}>
                <DialogContentText>
                    <b>Legend</b>
                </DialogContentText>
                <DialogContentText bgcolor={ScanSerialRowColor.Synced.Color} sx={{ p: 1, m: 1 }} variant="subtitle2">
                    Valid Serial
                </DialogContentText>
                <DialogContentText
                    sx={{
                        p: 1,
                        m: 1,
                        background: GET_DUPLICATE_SERIAL_BACKGROUND(),
                    }}
                    variant="subtitle2"
                >
                    Duplicate Serial
                </DialogContentText>
            </Paper>
            <Box sx={{ flex: 1 }}></Box>
        </Box>
    );
};

export default SerialTextfieldLegend;
