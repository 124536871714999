import { Box, Container, Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import DialogAppBar from '../../../../common/components/Dialog/DialogAppBar';
import useNavigationWarning from '../../../../common/hooks/useNavigationWarning';
import { setDialog, setSnackbar } from '../../../notification/notificationSlice';
import oldReceiversApi from '../../oldReceiversApi';
import { Inbound, SubmissionForm, SubmissionFormItem } from '../../receiversModels';
import { convertMinsToHours, isLadingError } from '../../receiversUtil';
import FormPartOne from './formPartOne/FormPartOne';
import ReceiverCreateDialogFormPartTwo from './formPartTwo/FormPartTwo';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ReceiversCreateDialogProps {
    isReceiversDialogOpen: boolean;
    setIsReceiversDialogOpen: Dispatch<SetStateAction<boolean>>;
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    isInEditMode: boolean;
    resetForm: () => void;
}

const ReceiversCreateDialog: React.FC<ReceiversCreateDialogProps> = ({
    isReceiversDialogOpen,
    setIsReceiversDialogOpen,
    form,
    setForm,
    isInEditMode,
    resetForm,
}) => {
    const dispatch = useDispatch();
    const momCode = useAppSelector(state => state.oldReceivers.momCode);
    const { data: inbounds } = oldReceiversApi.endpoints.getReceiverInboundsDeprecated.useQuery(
        momCode ? momCode : skipToken
    );
    const { data: stocks } = oldReceiversApi.endpoints.getReceiverStocksDeprecated.useQuery(
        momCode ? momCode : skipToken
    );

    const [isExitPromptOpen, setIsExitPromptOpen] = React.useState(false);
    useNavigationWarning({ shouldShowWarningOnNavigationChange: true });

    const [isRemainingFormShown, setIsRemainingFormShown] = useState<boolean>(false);

    const handleCreatingNewReceiverClose = () => {
        setIsExitPromptOpen(false);
        setIsReceiversDialogOpen(false);
        setIsRemainingFormShown(false);
        resetForm();
    };

    const openConfirmCloseDialog = () => {
        setIsExitPromptOpen(true);
    };

    const handleFormContinue = () => {
        const matchingInbound = inbounds?.find((element: Inbound) => form.Po === element.POReferenceNumber);
        if (matchingInbound !== undefined) {
            const inboundItems = matchingInbound.Items.map(
                (item): SubmissionFormItem => {
                    return {
                        Item: item.Sku,
                        LineId: item.LineId,
                        Descript: item.Description,
                        RecCrtncount: 0,
                        RecCaseqty: 0,
                        RecPartial: 0,
                        RecQty: 0,
                        PlQty: item.QtyExpected,
                        Notes: '',
                    };
                }
            );

            setForm(
                (prevForm: SubmissionForm): SubmissionForm => {
                    return {
                        ...prevForm,
                        RecDate: dayjs().format(),
                        Location: matchingInbound.Location || 'ENGLE-A',
                        UnitType: matchingInbound.ContainerLoad || 'Pallets',
                        Items: inboundItems,
                        Comment: matchingInbound.Notes,
                    };
                }
            );
        }
        setIsRemainingFormShown(true);
    };

    const [triggerFormUpdate, updateResponse] = oldReceiversApi.endpoints.updateReceiverDeprecated.useMutation();
    const [triggerFormCreate, createResponse] = oldReceiversApi.endpoints.createReceiverDeprecated.useMutation();

    const handleFormSubmission = () => {
        if (isLadingError(form.Lading, form.Project)) {
            dispatch(
                setDialog({ content: `BOL/Tracking field is required for submission`, title: `BOL/Tracking Needed` })
            );
        } else if (form.Items.length === 0) {
            dispatch(setDialog({ content: `At least one item is required for submission`, title: `Item Needed` }));
        } else if (form.Items.some(item => item.RecQty === 0)) {
            dispatch(setDialog({ content: `An added item can not have a quantity of zero`, title: `Invalid Item` }));
        } else if (
            form.Items.some(item => {
                const stockItem = stocks?.find(stock => stock.Sku === item.Item);
                if (!stockItem) return true;
                const availableStockCountOfItem = stockItem?.Available;
                return item.RecQty + availableStockCountOfItem < 0;
            })
        ) {
            dispatch(
                setDialog({
                    content: `Can not subtract less than available stock`,
                    title: `Invalid Item`,
                })
            );
        } else {
            const fixedForm = convertMinsToHours(form);

            if (isInEditMode) {
                triggerFormUpdate({ payload: fixedForm });
            } else {
                triggerFormCreate({ payload: fixedForm });
            }
            handleCreatingNewReceiverClose();
        }
    };

    React.useEffect(() => {
        if (!updateResponse.isUninitialized || !createResponse.isUninitialized) {
            if (updateResponse.isSuccess || createResponse.isSuccess) {
                dispatch(setSnackbar({ content: 'Submission Success', severity: 'success' }));
            } else if (updateResponse.isError || createResponse.isError) {
                dispatch(
                    setSnackbar({
                        content: `Submission error: ${updateResponse?.error || createResponse?.error}`,
                        severity: 'error',
                    })
                );
            }
        }
    }, [updateResponse, createResponse]);

    return (
        <Box>
            <Dialog
                fullScreen
                open={isReceiversDialogOpen}
                onClose={openConfirmCloseDialog}
                TransitionComponent={Transition}
            >
                <DialogAppBar
                    handleClose={openConfirmCloseDialog}
                    title={isInEditMode ? 'Edit Receiver' : 'Create Receiver'}
                />

                <Container>
                    <FormPartOne
                        form={form}
                        setForm={setForm}
                        handleFormContinue={handleFormContinue}
                        isInEditMode={isInEditMode}
                    />

                    {(isRemainingFormShown || isInEditMode) && (
                        <ReceiverCreateDialogFormPartTwo
                            form={form}
                            setForm={setForm}
                            handleFormSubmission={handleFormSubmission}
                            isInEditMode={isInEditMode}
                        />
                    )}
                </Container>
            </Dialog>

            <ConfirmDialog
                open={isExitPromptOpen}
                handleCancel={() => {
                    setIsExitPromptOpen(false);
                }}
                handleConfirm={handleCreatingNewReceiverClose}
                title={'Possibly Unsaved Changes'}
                contents={['Exit without saving changes?']}
            />
        </Box>
    );
};

export default ReceiversCreateDialog;
