import { Box, Button, Chip, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import componentsApi from '../../../common/components/componentsApi';
import { formatDayjsDateWithoutTime } from '../../../common/utils/dateUtil';
import {
    COMPLETE_STATUS,
    ERROR_STATUS,
    ERROR_STATUS_ID,
    NEW_STATUS,
    RMA_HISTORY_STATUS_PENDING,
    RMA_STATUS,
} from '../constants';
import { ProcessedHistory, ReceivedItem, ReceivedItemUnitDetail, Rma } from '../rmaModels';

export const ReportResultsDatagrid = React.memo(({ rmaResults }: { rmaResults: Rma[] }) => {
    const userResponse = componentsApi.endpoints.getUsers.useQuery();
    const getUserNameByCode = (code: string | null) => {
        if (!userResponse?.data || !code) {
            return '';
        }
        return userResponse.data.find(user => user.code === code)?.name || '';
    };

    const getRmaStatusColor = (status: string) => {
        if (status == ERROR_STATUS) {
            return 'error.main';
        }
        if (status == NEW_STATUS) {
            return 'warning.main';
        }
        if (status == COMPLETE_STATUS) {
            return 'success.main';
        }
        return 'primary.main';
    };

    const openInOrderLookup = (client: string, orderId: number | null | undefined) => {
        if (!client || !orderId) return;

        const newTab = window.open(`/orderLookup/${client}/${orderId}`, '_blank');
        if (newTab) newTab.focus();
    };

    const getImportStatusChip = (
        client: string,
        rmaStatus: number,
        processedHistory: ProcessedHistory | null | undefined
    ) => {
        if (!!processedHistory?.MomOrder) {
            return (
                <Chip
                    onClick={() => openInOrderLookup(client, processedHistory.MomOrder)}
                    size="small"
                    label="Created"
                    color="success"
                    clickable
                ></Chip>
            );
        }

        if (!!processedHistory?.OrderStatus && processedHistory.OrderStatus.trim() == RMA_HISTORY_STATUS_PENDING) {
            return <Chip size="small" label="Pending" color="warning"></Chip>;
        }

        if (rmaStatus == ERROR_STATUS_ID) {
            return <Chip size="small" label="Not Created" color="error"></Chip>;
        }

        return <Chip size="small" label="Not Created" color="primary"></Chip>;
    };

    const getActionLabel = (status: number, isProcessed: boolean) => {
        if (status == ERROR_STATUS_ID && !isProcessed) {
            return 'Resubmit';
        }

        return 'Details';
    };

    const columns: GridColDef[] = [
        {
            field: 'Client',
            headerName: 'Client',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
        },
        {
            field: 'Id',
            headerName: 'RMA',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            type: 'number',
            renderCell: (params: any) => {
                return <Box>{params.value}</Box>;
            },
        },
        {
            field: 'AltRmaNumber',
            headerName: 'Alt RMA',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueGetter: (value: string | null) => {
                return !!value ? value : '-';
            },
        },
        {
            field: 'TrackingNumbers',
            headerName: 'Tracking',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            valueGetter: (value: string[] | null) => {
                return !!value && value.length > 0 ? value[0] : '-';
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueGetter: value => {
                return RMA_STATUS[value];
            },
            renderCell: (params: any) => {
                return <Box color={getRmaStatusColor(params.value)}>{params.value}</Box>;
            },
        },
        {
            field: 'DateUpdated',
            headerName: "Proc'd Date",
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            type: 'number',
            valueGetter: value => {
                return formatDayjsDateWithoutTime(value);
            },
        },
        {
            field: 'ProcessedBy',
            headerName: 'By',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueGetter: (value: string) => {
                return !!value ? value.toUpperCase() : '';
            },
            renderCell: (params: any) => {
                return (
                    <Box>
                        <Tooltip title={getUserNameByCode(params.value)} placement="top-start">
                            {params.value}
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: 'ProcessedHistory',
            headerName: 'Return Order',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            renderCell: (params: any) => {
                return getImportStatusChip(params.row.Client, params.row.Status, params.row.ProcessedHistory);
            },
        },
        {
            field: 'OriginalOrderId',
            headerName: 'Original Order',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            valueGetter: (value: string | null) => {
                return !!value ? value : null;
            },
            renderCell: (params: any) => {
                return !!params.value ? (
                    <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                            openInOrderLookup(params.row.Client, params.value);
                        }}
                    >
                        {params.value}
                    </Button>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'ReceivedItems',
            headerName: 'Line Qty',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'number',
            valueGetter: (value: ReceivedItem[] | null) => {
                return !!value && value.length > 0 ? value.length : 0;
            },
        },
        {
            field: 'TotalUnits',
            headerName: 'Total Qty',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'number',
            valueGetter: (params: any, row) => {
                const receivedItems = row?.ReceivedItems || [];
                return receivedItems.reduce(
                    (total: number, item: any) => total + (item.ItemUnitDetails.length || 0),
                    0
                );
            },
        },
        {
            field: 'Serials',
            headerName: 'Serial Qty',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'number',
            valueGetter: (params: any, row) => {
                const serialsLength = row.ReceivedItems.map((item: ReceivedItem) =>
                    item.ItemUnitDetails.map((unit: ReceivedItemUnitDetail) => unit.Serials.length)
                )
                    .flat()
                    .reduce((total: number, num: number) => total + num, 0);
                return serialsLength;
            },
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: '_',
            headerName: 'Action',
            flex: 1,
            type: 'actions',
            renderCell: (params: any) => {
                return (
                    <Button
                        size="small"
                        onClick={() => {
                            const newTab = window.open(
                                `/rma/process?client=${params.row.Client}&rmaId=${params.row.Id}`,
                                '_blank'
                            );
                            if (newTab) newTab.focus();
                        }}
                    >
                        {getActionLabel(params.row.Status, params.row?.ProcessedHistory?.Id)}
                    </Button>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <DataGrid
                getRowId={row => row.Id}
                rows={rmaResults || []}
                columns={columns}
                autoHeight
                disableRowSelectionOnClick
                scrollbarSize={20}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'DateUpdated', sort: 'desc' }],
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                    columns: {
                        columnVisibilityModel: {
                            ReceivedItems: false,
                            TotalUnits: false,
                            Serials: false,
                            OriginalOrderId: false,
                        },
                    },
                }}
            />
        </React.Fragment>
    );
});
