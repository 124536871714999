import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/store';

export const RmaIdDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);
    const rmaId = () => {
        return <Typography variant="h6">RMA: {rma?.Id || '(Not Created)'}</Typography>;
    };
    if (rma?.AltRmaNumber) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                {rmaId()}
                <Typography variant="h6">ALT RMA: {rma?.AltRmaNumber}</Typography>
            </Box>
        );
    }
    if (rma?.TrackingNumbers.length == 1) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                {rmaId()}
                <Typography variant="h6">TRACKING: {rma?.TrackingNumbers[0]}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
            {rmaId()}
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                {(!rma?.TrackingNumbers || rma.TrackingNumbers.length > 0) && (
                    <Typography>TRACKING NUMBERS:</Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {rma?.TrackingNumbers.map(number => (
                        <Typography key={rma.Id}>{number}</Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
