import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import ConfirmDialog from '../../../../../common/components/Dialog/ConfirmDialog';
import CompanyDropdownV3 from '../../../../../common/components/dropdown/CompanyDropdownV3';
import { formatDate } from '../../../../../common/utils/dateUtil';
import { propertyOf } from '../../../../../common/utils/typedCommonUtils';
import receiversApi from '../../../receiversApi';
import { ActiveRuleMap, SubmissionForm } from '../../../receiversModels';
import { overrideForm } from '../../../receiversSlice';
import { getOptions } from '../../../receiversUtil';
import { RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING } from '../../../styles';
import ReceiverDialogTextField from './ReceiverDialogTextField';

const TextFieldsFirstHalf = ({ hasAsn, activeRules }: { hasAsn: boolean; activeRules: ActiveRuleMap }) => {
    const mode = useAppSelector(state => state.receivers.mode);
    const dispatch = useAppDispatch();

    const Client = useAppSelector(state => state.receivers.form.Client);
    const Items = useAppSelector(state => state.receivers.form.Items);
    const Project = useAppSelector(state => state.receivers.form.Project);
    const Location = useAppSelector(state => state.receivers.form.Location);
    const RecDate = useAppSelector(state => state.receivers.form.RecDate);
    const RecBy = useAppSelector(state => state.receivers.form.RecBy);
    const Po = useAppSelector(state => state.receivers.form.Po);
    const UnitType = useAppSelector(state => state.receivers.form.UnitType);

    const [changeCompanyDialogState, setChangeCompanyDialogState] = React.useState<{
        isDialogOpen: boolean;
        newCompany?: string;
    }>({ isDialogOpen: false, newCompany: undefined });

    const getReceiversConfigResponse = receiversApi.endpoints.getReceiversConfig.useQuery(Client ? Client : skipToken);

    return (
        <>
            <CompanyDropdownV3
                disableClearable
                sx={RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING}
                disabled={hasAsn || mode === 'Edit'}
                readOnly={mode === 'View'}
                autoFocus={true}
                onChange={(event, newValue) => {
                    const newMomValue = newValue?.mom ?? '';

                    if (Items.length > 0) setChangeCompanyDialogState({ isDialogOpen: true, newCompany: newMomValue });
                    else {
                        dispatch(overrideForm({ Client: newMomValue, Po: '' }));
                    }
                }}
                textFieldProps={{
                    variant: 'filled',
                    helperText: ' ',
                    required: true,
                    label: 'Company',
                }}
                value={Client}
            />

            <ConfirmDialog
                title="Delete All Line Items?"
                contents={['Changing companies will delete all line items. Continue?']}
                open={changeCompanyDialogState.isDialogOpen}
                handleConfirm={() => {
                    dispatch(overrideForm({ Client: changeCompanyDialogState.newCompany ?? '', Po: '', Items: [] }));
                    setChangeCompanyDialogState({ isDialogOpen: false });
                }}
                handleCancel={() => {
                    setChangeCompanyDialogState({ isDialogOpen: false });
                }}
            />

            <ReceiverDialogTextField
                label="Project"
                value={Project}
                onChange={newValue => {
                    dispatch(overrideForm({ Project: newValue }));
                }}
                options={getOptions(getReceiversConfigResponse?.data, propertyOf<SubmissionForm>('Project'))}
                disabled={hasAsn || mode === 'Edit'}
                rule={activeRules?.Project}
                shouldClearOnDisable={false}
            />

            <ReceiverDialogTextField
                label="Location"
                value={Location}
                onChange={newValue => {
                    dispatch(overrideForm({ Location: newValue }));
                }}
                options={getOptions(getReceiversConfigResponse?.data, propertyOf<SubmissionForm>('Location'))}
                rule={activeRules?.Location}
            />
            <ReceiverDialogTextField
                label="Received Date"
                value={formatDate(RecDate)}
                onChange={newValue => {
                    dispatch(overrideForm({ RecDate: newValue }));
                }}
                disabled={true}
                rule={activeRules?.RecDate}
            />
            <ReceiverDialogTextField
                label="Received By"
                value={RecBy}
                onChange={newValue => {
                    dispatch(overrideForm({ RecBy: newValue }));
                }}
                disabled={true}
                rule={activeRules?.RecBy}
            />
            <ReceiverDialogTextField
                label={hasAsn ? 'Advanced Shipping Notice (ASN)' : 'PO'}
                value={Po}
                onChange={newValue => {
                    dispatch(overrideForm({ Po: newValue }));
                }}
                disabled={hasAsn}
                rule={activeRules?.Po}
            />
            <ReceiverDialogTextField
                label="Unit Type"
                value={UnitType}
                onChange={newValue => {
                    dispatch(
                        overrideForm({
                            UnitType: newValue,
                        })
                    );
                }}
                options={getOptions(getReceiversConfigResponse?.data, propertyOf<SubmissionForm>('UnitType'))}
                rule={activeRules?.UnitType}
            />
        </>
    );
};

export default React.memo(TextFieldsFirstHalf);
