import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/List';
import PauseIcon from '@mui/icons-material/NotStartedOutlined';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import PrimaryActionCard from '../../common/components/Card/PrimaryActionCard';
import Page from '../../common/components/container/Page';
import { INBOUND_SLUG } from '../authentication/slugs';
import { breadcrumbNameMap } from './components/inbound/const';
import { resetForm, setMode } from './receiversSlice';

const Inbound = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <Page
            title={'Inbound'}
            breadcrumbNameMap={breadcrumbNameMap}
            childrenElement={
                <Box sx={{ display: 'flex', flexFlow: 'wrap', flexDirection: 'column' }}>
                    <PrimaryActionCard
                        titleText="With ASN"
                        bodyText="Create receiver against existing Advanced Shipment Notice (ASN)"
                        imageElement={<ViewListIcon sx={{ flex: 1, fontSize: 100 }} />}
                        onClick={() => {
                            navigate(`/${INBOUND_SLUG}/ASN`);
                        }}
                    />
                    <PrimaryActionCard
                        titleText="No ASN"
                        bodyText="Create receiver with no Advanced Shipment Notice (ASN)"
                        imageElement={<AddIcon sx={{ flex: 1, fontSize: 100 }} />}
                        onClick={() => {
                            dispatch(setMode('Create'));
                            dispatch(resetForm());
                            navigate(`/${INBOUND_SLUG}/New`);
                        }}
                    />
                    <PrimaryActionCard
                        titleText="Existing"
                        bodyText="Resume an Existing Receiver"
                        imageElement={<PauseIcon sx={{ flex: 1, fontSize: 100 }} />}
                        onClick={() => {
                            navigate(`/${INBOUND_SLUG}/Resume`);
                        }}
                    />
                </Box>
            }
        />
    );
};

export default Inbound;
