import { Box, Button, TextField } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import { parseTrackingNumberV2 } from '../../../common/utils/typedCommonUtils';
import textFieldFocus from '../../rapidQc/utils/textFieldFocus';
import { DEFAULT_FROM_SEARCH_DAYS_BACK, INPUT_DATETIME_FORMAT, RMA_PROCESS_SLUG } from '../constants';
import rmaApi from '../rmaApi';
import { Rma } from '../rmaModels';
import { setParentRma, setReset, setRma as setRmaSlice } from '../rmaSlice';
import { captureClientCodeFromLabel, submitOnEnterKeyDown } from '../utilities';
import { ChooseRmaDialog } from './ChooseRmaDialog';
import { ExpectedItemsDialog } from './ExpectedItems/ExpectedItemsDialog';
import { RmaNotFoundDialog } from './ExpectedItems/RmaNotFoundDialog';
import { ParentRmaDialog } from './ParentRmas/ParentRmaDialog';
import { CreateRmaDialog } from './ReceivedItems/CreateNewRma/CreateRmaDialog';

const SERVER_ERROR_MSG = 'Could not correctly retrieve data, please try again later.';

export const LandingPageDisplay = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const rma = useAppSelector(state => state.rma.rma);
    const parentRma = useAppSelector(state => state.rma.parentRma);
    const defaultSearchFromDate = dayjs().subtract(DEFAULT_FROM_SEARCH_DAYS_BACK, 'day').format(INPUT_DATETIME_FORMAT);
    const [fromSearchDate, setFromSearchDate] = React.useState<string | null>(defaultSearchFromDate);
    const [rmaQuery, setRmaQuery] = React.useState('');
    const [currentClient, setCurrentClient] = React.useState('');
    const [expectedItemsDialog, setExpectedItemsDialog] = React.useState(false);
    const [rmaNotFoundDialog, setRmaNotFoundDialog] = React.useState(false);
    const [multipleRmasDialog, setMultipleRmasDialog] = React.useState(false);
    const [newRmaDialog, setNewRmaDialog] = React.useState(false);
    const [parentRmaDialog, setParentRmaDialog] = React.useState(false);
    const [rmas, setRmas] = React.useState<Rma[]>([]);

    const justReseted = useAppSelector(state => state.rma.reset); // Set to true after confirming summary submission
    const returnLabelTextfieldRef = useRef<HTMLInputElement>(null);

    const momCode: string | null = searchParams.get('client');
    const rmaId = parseInt(searchParams.get('rmaId') || '', 10) || null;
    const getByRmaIdResponse = rmaApi.endpoints.getRmaById.useQuery(
        momCode && !!rmaId ? { momCode, rmaId } : skipToken
    );

    const [triggerGetRma, rmaResponse] = rmaApi.endpoints.getRma.useLazyQuery();
    const [triggerGetParentRma, rmaParentResponse] = rmaApi.endpoints.getParentRma.useLazyQuery();
    const [triggerGetReturnLabel, returnLabelResponse] = rmaApi.endpoints.getReturnLabel.useMutation();

    useNotificationByResponse({
        isFetching: rmaResponse.isFetching,
        isError: rmaResponse.isError,
        errorContent: SERVER_ERROR_MSG,
        isUninitialized: rmaResponse.isUninitialized,
    });

    const submitParentRmaTracking = () => {
        const parsedValue = parseTrackingNumberV2(rmaQuery)?.replace(/\n/g, '') || rmaQuery;
        dispatch(setParentRma(null));
        dispatch(setRmaSlice(null));
        setCurrentClient('');
        cleanUrl();
        setFromSearchDate(defaultSearchFromDate);
        setRmaQuery(parsedValue);
        if (!!parsedValue) {
            triggerGetParentRma({ tracking: parsedValue });
        }
    };

    // Return rmas with unique client and rma numbers
    const getUniqueClientRma = (rawRmaList: Rma[]) => {
        const uniqueRma = new Set();
        return rawRmaList.filter(rma => {
            const identifier = `${rma.Client}-${rma.AltRmaNumber}-${rma.RmaNumber}`;
            if (!uniqueRma.has(identifier)) {
                uniqueRma.add(identifier);
                return true;
            }
            return false;
        });
    };

    const getReturnLabel = () => {
        if (!!returnLabelResponse.data && returnLabelResponse.data?.length > 0) {
            return returnLabelResponse.data[0];
        }
        return null;
    };

    const clearAndFocusRmaTextfield = () => {
        setRmaQuery('');
        textFieldFocus({ textFieldRef: returnLabelTextfieldRef });
    };

    const onSuccessfulRmaResponse = () => {
        if (!rmaResponse.data?.Data) {
            return;
        }

        const uniqueRmas = getUniqueClientRma(rmaResponse.data.Data);
        if (uniqueRmas.length == 1) {
            dispatch(setRmaSlice(uniqueRmas[0]));
            setExpectedItemsDialog(true);
        } else if (uniqueRmas.length > 1) {
            setRmas(uniqueRmas);
            setMultipleRmasDialog(true);
        }
    };

    const openParentRmaOnReset = () => {
        if (!!parentRma && parentRma.TrackingNumbers.length > 0) {
            triggerGetParentRma({ tracking: parentRma.TrackingNumbers[0] });
        } else if (!!rma && rma.TrackingNumbers.length > 0) {
            triggerGetParentRma({ tracking: rma.TrackingNumbers[0] });
        }
    };

    const cleanUrl = () => {
        window.history.pushState({}, '', `/Rma/${RMA_PROCESS_SLUG}`);
    };

    const doJustSubmittedActions = () => {
        setExpectedItemsDialog(false);
        setNewRmaDialog(false);
        dispatch(setReset(false));
        clearAndFocusRmaTextfield();
        openParentRmaOnReset();
    };

    React.useEffect(() => {
        if (!rmaResponse.isUninitialized && !rmaResponse.isFetching && !!rmaQuery) {
            if (rmaResponse.data?.Success && rmaResponse.data.Data.length > 0) {
                if (!currentClient) {
                    const client = rmaResponse.data.Data[0].Client;
                    if (!!client) {
                        setCurrentClient(client);
                        triggerGetRma({ MomCode: client, Query: rmaQuery, FromProcessedDate: fromSearchDate });
                    }
                } else {
                    onSuccessfulRmaResponse();
                }
            } else if (!!fromSearchDate) {
                setFromSearchDate(null);
                triggerGetRma({
                    MomCode: null,
                    Query: rmaQuery,
                    OmitOriginalOrder: true,
                    OmitSerialValidationInfo: true,
                    FromProcessedDate: null,
                });
            } else {
                clearAndFocusRmaTextfield();
                setRmaNotFoundDialog(true);
            }
        }
    }, [rmaResponse]);

    React.useEffect(() => {
        if (!returnLabelResponse.isUninitialized && !returnLabelResponse.isLoading && !!rmaQuery) {
            // Attempt to get return label
            if (!!returnLabelResponse.data && returnLabelResponse.data?.length > 0) {
                const newestReturnlabel = returnLabelResponse.data[0];
                const possibleClientCode = captureClientCodeFromLabel(newestReturnlabel.reference) || null;
                triggerGetRma({ MomCode: possibleClientCode, Query: newestReturnlabel.rma });
                // If no record found, treat scan as tracking or rma number
            } else {
                triggerGetRma({
                    MomCode: null,
                    Query: rmaQuery,
                    OmitOriginalOrder: true,
                    OmitSerialValidationInfo: true,
                    FromProcessedDate: defaultSearchFromDate,
                });
            }
        }
    }, [returnLabelResponse]);

    React.useEffect(() => {
        if (!rmaParentResponse.isUninitialized && !rmaParentResponse.isFetching) {
            // Attempt to get parent RMA
            if (!!rmaParentResponse.data?.Success && rmaParentResponse.data.Data.length > 0) {
                setParentRmaDialog(true);
                dispatch(setParentRma(rmaParentResponse.data.Data[0]));
            } else if (!!rmaQuery) {
                triggerGetReturnLabel({ refPo: rmaQuery });
            }
        }
    }, [rmaParentResponse]);

    React.useEffect(() => {
        if (!getByRmaIdResponse.isUninitialized && !getByRmaIdResponse.isFetching) {
            if (getByRmaIdResponse.data?.Success && getByRmaIdResponse.data.Data.length > 0) {
                if (!getByRmaIdResponse.data?.Data) {
                    return;
                }
                dispatch(setRmaSlice(getByRmaIdResponse.data?.Data[0]));
                setExpectedItemsDialog(true);
            }
        }
    }, [getByRmaIdResponse]);

    React.useEffect(() => {
        if (justReseted) {
            doJustSubmittedActions();
        }
    }, [justReseted]);

    return (
        <React.Fragment>
            <ExpectedItemsDialog showDialog={expectedItemsDialog} handleOpenDialog={setExpectedItemsDialog} />
            <RmaNotFoundDialog
                showDialog={rmaNotFoundDialog}
                handleOpenDialog={setRmaNotFoundDialog}
                handleNewRmaDialog={setNewRmaDialog}
                returnLabel={getReturnLabel()}
            />
            <CreateRmaDialog showDialog={newRmaDialog} handleOpenDialog={setNewRmaDialog} />
            <ChooseRmaDialog
                rmas={rmas}
                showDialog={multipleRmasDialog}
                handleOpenDialog={setMultipleRmasDialog}
                setExpectedItemsDialog={setExpectedItemsDialog}
            />
            <ParentRmaDialog showDialog={parentRmaDialog} handleDialogToggle={setParentRmaDialog} />
            <Box sx={{ display: 'flex' }}>
                <TextField
                    value={rmaQuery}
                    inputRef={returnLabelTextfieldRef}
                    autoFocus
                    sx={{ flex: 6 }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        submitOnEnterKeyDown(event, submitParentRmaTracking);
                    }}
                    label={'SCAN RETURN LABEL, TRACKING, OR RMA NUMBER'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setRmaQuery(event.target.value.trim());
                    }}
                />
                <Button sx={{ flex: 1, ml: 1 }} variant="contained" onClick={submitParentRmaTracking}>
                    SUBMIT
                </Button>
            </Box>
        </React.Fragment>
    );
};
