import { Box, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import CsvForm from './form/CsvForm';
import InitialScanForm from './form/InitialScanForm';

const ClearSerialElement = () => {
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Paper sx={{ mt: 2 }} elevation={8}>
            <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                <Tab label="Scan" />
                <Tab label="CSV" />
            </Tabs>

            <Box sx={{ p: 2 }}>
                {tabIndex === 0 && <InitialScanForm />}
                {tabIndex === 1 && <CsvForm />}
            </Box>
        </Paper>
    );
};

export default ClearSerialElement;
