// Mode

// Base url
export const BASE_URL = 'https://roapi.rushorder.com/';
export const BASE_URL_DEV = 'https://roapi-staging.rushorder.com/';
// export const BASE_URL_DEV = 'https://localhost:44330/';

export const DREAM_BASE_URL = 'https://dream.rushorder.com/';
export const DREAM_STAGING_BASE_URL = 'https://staging.dream.rushorder.com/';
export const DREAM_BASE_URL_DEV = 'http://localhost:61649/';

export const ROAPI_URL = process.env.REACT_APP_MODE === 'dev' ? BASE_URL_DEV : process.env.REACT_APP_ROAPI_URL;
export const DREAM_URL = process.env.REACT_APP_MODE === 'dev' ? DREAM_BASE_URL_DEV : process.env.REACT_APP_DREAM_URL;
export const RO_AGENT_URL = 'http://localhost:6600/';

//Auth
export const AUTH_REFRESH = 'api/Authentication/refresh';

//Application Version
export const GET_APPLICATION_VERSION_URL = '/api/v1/Application/SEAL/version';

//Ship Matrix
export const GET_GROUP_DICT_URL = '/api/rateshopping/groups';
export const GET_SHIP_METHODS_URL = '/api/rateshopping/shipMethods';
export const GET_RATE_SHOPPING_COMPANY_URL = '/api/rateshopping/companies';
export const GET_MATRIX_URL = '/api/rateshopping';
export const GET_SHIP_METHOD_URL = '/api/rateshopping';
export const EDIT_CLIENTS_URL = '/api/rateshopping/client';
export const EDIT_RATE_SHOPPING_URL = '/api/rateshopping';
export const GET_MAP_EDIT_LOG_URL = '/api/rateshopping/map/editLog';
export const GET_CLIENT_EDIT_LOG_URL = '/api/rateshopping/client/editLog';

//Work Order
export const WORK_ORDER_SERVICES_URL = '/api/workorder/services';
export const WORK_ORDER_BILL_OF_MATERIALS_URL = '/api/workorder/BillOfMaterialsOptions';

export const GET_WORK_ORDERS_URL = '/api/workorder/filtered';
export const GET_DETAILED_WORK_ORDER_URL = '/api/workorder/detailed';
export const POST_WORK_ORDER_URL = '/api/workorder/addWorkOrder';
export const COMPLETE_WORK_ORDER_URL = '/api/workorder/complete';
export const DELETE_WORK_ORDER_URL = '/api/workorder/delete';
export const GET_END_OF_MONTH_REPORT_URL = '/api/workorder/EndOfMonthReport';
export const GET_TEMPLATE_TYPE_URL = '/api/workorder/templateType';

//Clear Serials
export const GET_SERIAL_STATUS_ENDPOINT = '/api/seal/serial/status';
export const CLEAR_SERIALS_ENDPOINT = '/api/seal/serial/clear';

//Sku Velocity
export const GET_SKU_VELOCITY_ENDPOINT = '/api/velocity';
export const GET_SKU_VELOCITY_LOCATIONS_ENDPOINT = '/api/velocity/location';

//Procedure
export const IMAGE_URL = 'https://assets.rushorder.com/images/swrop/';
export const PROCEDURE_URL = '/api/seal/procedure';
export const WORK_ORDER_TO_PROCEDURE_MAP_URL = '/api/seal/procedure/WorkOrderProcedure';
export const FILTERED_PROCEDURES_URL = '/api/seal/procedure/filtered';
export const INSTRUCTION_URL = '/api/seal/procedure/instruction';
export const NEXT_INSTRUCTION_MAP_URL = '/api/seal/procedure/nextInstructionMap';
export const RECEIVER_ITEM_URL = '/api/seal/procedure/receiverItem';
export const VARIABLE_URL = '/api/seal/procedure/variable';
export const SESSION_URL = '/api/seal/procedure/session';
export const START_SESSION_URL = '/api/seal/procedure/session/start';
export const CONTINUE_SESSION_URL = '/api/seal/procedure/session/continue';
export const COMPLETE_SESSION_URL = '/api/seal/procedure/session/complete';
export const ACTIVE_SESSION_URL = '/api/seal/procedure/activeSession';
export const RECEIVER_URL = '/api/seal/procedure/receiver';

//Serial Location
export const GET_INBOUND_SERIAL_URL = '/api/seal/serial/inbound';
export const GET_OUTBOUND_SERIAL_URL = '/api/seal/serial/outbound';
export const GET_ONHAND_SERIAL_URL = '/api/seal/serial/onhand';
export const GET_SERIAL_LOCATION_URL = '/api/warehouse/highValueCage';

//Add serial
export const POST_ADD_SERIAL_URL = '/api/order/addSerial';

//Rma
export const GET_RMA_ITEMS_URL = '/api/rma/items';
export const POST_RMA_SERIAL_URL = '/api/rma/serial';

//Tracking
export const POST_TRACKING_URL = 'api/orderdetails/tracking';
export const GET_CARRIERS_URL = 'api/orderdetails/tracking/carriers';

//Receivers
export const GET_SEAL_RECEIVERS_URL = 'api/seal/receivers';
export const GET_RECEIVERS_URL = 'api/receiver';
export const GET_RECEIVERS_CONFIG_URL = 'api/seal/receivers/config';
export const GET_RECEIVERS_INBOUNDS_URL = 'api/inbound';
export const GET_RECEIVERS_STOCKS_URL = 'api/internal/stock/';
export const GET_RECEIVERS_ITEM_URL = 'api/internal/stock/receiving';
export const POST_RECEIVERS_FORM_URL = 'api/receiver';
export const GET_RECEIVERS_HISTORY_URL = 'api/receiver/history';
export const PLAY_RECEIVERS_URL = 'api/playreceiver';

// Task
export const GET_TASKS_INSTRUCTIONS_URL = 'api/tasks/GetTaskInstructions/';
export const GET_TASK_CHECKLIST_URL = 'api/tasks/GetTaskChecklists/';
export const GET_USER_TASKS_URL = 'api/v1/Tasks/GetByFilter'; //new
export const GET_REVIEW_TASK_DETAILS_URL = 'api/v1/Tasks/GetReviewTaskDetails';

//Quality Control
export const GET_QC_ORDER_STATUS_URL_PREFIX = 'api/qc/order/';
export const GET_QC_ORDER_STATUS_URL_POSTFIX = '/status';
export const GET_QC_ORDER_DATA_URL = 'api/qc/order/';
export const SUBMIT_QC_URL = 'api/qc/submit/';
export const PARTIAL_SUBMIT_QC_URL = 'api/qc/partialSubmit/';
export const GET_QC_SERIAL_CHECK_URL = 'api/qc/checkSerial/';
export const START_QC_URL_POSTFIX = '/start';
export const COMPLETE_QC_URL_POSTFIX = '/complete';
export const ABORT_QC_URL_POSTFIX = '/abort';
export const RESET_QC_URL_POSTFIX = '/reset';
export const PAUSE_QC_URL_POSTFIX = '/pause';
export const RESET_MANIFEST_URL_POSTFIX = '/resetmanifest';

// Pallet
export const GET_PALLETS_FOR_TASK_URL = 'api/v1/PalletCounts/GetByFilter'; //new
export const GET_PALLETS_BY_ID_URL = 'api/v1/Palletcounts';
export const UPDATE_COUNT_TASK_URL = 'api/v1/PalletCounts';
export const GET_COUNT_RESULT_URL = 'api/tasks/GetCountResults';
export const GENERATE_PALLET_COUNT_URL = 'api/v1/PalletCounts/GeneratePalletCount';
export const GET_PALLET_FILTERS_URL = '/api/v1/PalletCounts/GetPalletFilters';

// STOCK
export const SEARCH_PALLET_BY_FILTERS_URL = '/api/v1/Stock/SearchPalletsByFilters';
export const GET_STOCK_PALLET_FILTERS_URL = '/api/v1/Stock/GetPalletFilters';
export const GET_STOCK_PALLET_LOGS_URL = '/api/v1/Stock/PalletLog';
// Create Task
export const GET_COMPANIES_URL = 'api/v1/Companies';
export const GET_USERS_URL = 'api/v1/Users/GetMomUsers';
export const GET_SKUS_FOR_COMPANY_URL = 'api/v1/Companies/GetSkusByCompany';
export const CREATE_TASK_URL = 'api/v1/Tasks/CreateCountTask';

//Company Skus
export const GET_COMPANY_SKUS = 'api/v1/Tasks/GetCompanySkuByTaskState';

//Task Details based on Skus
export const GET_TASK_DETAILS_BASED_ON_SKU = 'api/v1/CountTasks/GetTaskDetailBySku';

//Reject Task Details
export const GET_REJECT_TASK_DETAILS = 'api/v1/Tasks/RejectTask';

//Approve Task Details
export const GET_APPROVE_TASK_DETAILS = 'api/v1/Tasks/ApproveTask';

//Discard Taskdetails
export const DISCARD_TASK = 'api/v1/Tasks/DiscardTask';

//Re-Assign Task Details
export const GET_REASSIGN_TASK_DETAILS = 'api/v1/Tasks/ReassignTask';

// Re-Assign User to task
export const REASSIGN_TASK_USER = 'api/v1/Tasks/ReassignUser';

// retire pallet api
export const RETIRE_PALLET_TAG_URL = 'api/v1/Stock/RetirePalletTag';

// QUARANTINE pallet api
export const QUARANTINE_PALLET_TAG_URL = 'api/v1/Stock/QuarantinePalletTag';

// Clone pallet api
export const CLONE_PALLET_TAG_URL = 'api/v1/Stock/ClonePalletTag';

// Validate pallet api
export const VALIDATE_PALLET_TAG_URL = 'api/v1/Stock/ValidatePalletTag';

// update pallet location api
export const UPDATE_PALLET_LOCATION_URL = 'api/v1/Stock/UpdatePalletLocation';
export const GET_WAREHOUSE_LIST_URL = 'api/v1/Stock/GetWarehouse';
export const GET_LOCATION_BY_WAREHOUSE_URL = 'api/v1/Stock/GetLocationByFilter';

// ROLE APIS
export const ROLE_GET_ALL_USERS_URL = 'api/v1/Role/GetAllUsers';
export const ROLE_URL = 'api/v1/Role';
export const EDIT_USER_ROLE_URL = 'api/v1/Role/EditUsersInRole';
export const GET_ALL_ROLE_PERMISSION_URL = 'api/v1/Role/GetAllPermission';
export const EDIT_ROLE_PERMISSIONS_URL = 'api/v1/Role/EditRolePermission';
export const GET_USER_PERMISSION_URL = 'api/v1/Role/GetUserPermission';

// USERS APIS
export const GET_ASSIGNED_ROLES_BY_USER_URL = '/api/v1/Role/GetAssignedRolesByUser';
export const EDIT_ROLES_BY_USER_URL = '/api/v1/Role/EditRolesByUser';

// Orders
export const GET_SHIP_HISTORY_BY_YEAR_URL = 'api/v1/Dashboard/GetShipHistoryByYear';
export const GET_WAREHOUSE_URL = 'api/v1/Dashboard/GetDropDownWareHouse';
export const GET_SHIP_HISTORY_BY_DATE_RANGE_URL = 'api/v1/Dashboard/GetShipHistoryByDateRange';
export const GET_SHIP_HISTORY_BY_YEAR_MONTH_URL = 'api/v1/Dashboard/GetShipHistoryByYearMonthWarehouse';
export const GET_ORDER_BY_WAREHOUSE_URL = 'api/v1/Dashboard/GetOrderbyWarehouse';
export const GET_DASHBOARD_WAREHOUSE_URL = 'api/v1/Dashboard/GetDashboardWarehouse';

// SEAL APIs - Order Lookup
export const GET_SEAL_ORDER_LOOKUP_URL = 'api/seal/orderlookup';
export const PUT_SEAL_UPDATE_SERIAL_URL = 'api/seal/serial/update';
export const DELETE_SEAL_DELETE_SERIAL_URL = 'api/seal/serial/delete';
export const POST_SEAL_ADD_SERIALS_URL = 'api/seal/serial/add';
export const GET_SEAL_HISTORY_ORDER_URL = 'api/seal/history';
export const POST_BUILD_COMMERICAL_INVOICE_PDF_URL = 'api/invoice/commercial';

// Rapid QC
export const BATCH_ID_URL = 'api/qc/batch';

// RMA
export const GET_SEAL_RMA_URL = 'api/seal/rma';
export const GET_SEAL_PARENT_RMA_URL = 'api/seal/rma/parentRma';
export const GET_SEAL_RMA_STOCK_URL = 'api/seal/rma/stock';
export const GET_RMA_NUMBER_FROM_RETURN_LABEL_URL = 'api/v1/ReturnLabel';
export const GET_SEAL_RMA_DISPOSITIONS_URL = 'api/seal/rma/dispositions';
export const POST_SEAL_RMA_MISSING_SERIAL_REASONS_URL = 'api/seal/rma/missingSerialReasons';
export const POST_SEAL_RMA_RECEIVED_ITEMS_URL = 'api/seal/rma/receivedItems';
export const POST_SEAL_RMA_SKU_IMAGE_URL = 'api/seal/rma/skuImage';
export const POST_SEAL_NEW_RMA_URL = 'api/seal/rma/addRma';
export const POST_SEAL_ADD_RMA_HISTORY_URL = 'api/seal/rma/addRmaHistory';

// Batch Order Monitor
export const GET_SEAL_BATCH_ORDER_COUNTS_URL = 'api/seal/batchOrders/counts';
export const GET_SEAL_BATCH_ORDERS_URL = 'api/seal/batchOrders';
