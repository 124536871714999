import { Box, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function PrimaryActionCard({
    titleText,
    bodyText,
    imageElement,
    onClick,
}: {
    titleText: string;
    bodyText: string;
    imageElement: JSX.Element;
    onClick: () => void;
}) {
    return (
        <Card sx={{ minWidth: 200, flex: 1, m: 2 }}>
            <CardActionArea onClick={onClick} sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', m: 3 }}>{imageElement}</Box>
                <CardContent sx={{ flex: 1 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {titleText}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {bodyText}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
