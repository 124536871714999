import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import * as PapaParse from 'papaparse';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files-ro';
import { useAppDispatch } from '../../../../app/store';
import useMutationResponse from '../../../../common/hooks/useMutationResponse';
import { setDialog } from '../../../notification/notificationSlice';
import clearSerialApi from '../../clearSerialApi';
import { SerialFilter, SerialRecord } from '../../clearSerialModels';
import SerialStatusDataGrid from '../SerialStatusDataGrid';

const ACCEPTED_FILE_TYPES = ['csv'];
const EXAMPLE_CSV_PATH = '../exampleClearSerialUpload.csv';

const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
};

const CsvForm = () => {
    const dispatch = useAppDispatch();
    const [serialsToClear, setSerialsToClear] = React.useState<SerialRecord[]>([]);
    const [reason, setReason] = React.useState('');
    const [triggerClearSerials, clearSerialsResponse] = clearSerialApi.endpoints.clearSerials.useMutation();

    useMutationResponse({
        response: clearSerialsResponse,
        errorMessage: 'Failed to clear serial',
        successMessage: 'Successfully cleared serial',
    });

    const handleFileUpload = async (file: File) => {
        PapaParse.parse(file, {
            complete: function (results) {
                const firstRow = results?.data[0] as SerialFilter;
                const areHeadersValid = firstRow?.Client && firstRow?.Sku && firstRow?.Serial;
                if (!areHeadersValid) {
                    dispatch(
                        setDialog({
                            title: 'Invalid CSV',
                            content: `Must match format: \nClient,Sku,Serial
                                        FRAN,M2-DOCK-US,N77984T
                                        STX,111AOCF-A,STRA01138013446`,
                        })
                    );
                    return;
                }

                const serials = results.data.map((row: any) => {
                    return {
                        Client: row.Client.trim(),
                        Serial: row.Serial.trim(),
                        Sku: row.Sku.trim(),
                    } as SerialFilter;
                });

                let result: SerialFilter[] = [];
                for (const newSerial of serials) {
                    if (
                        serialsToClear.some(
                            oldSerial =>
                                oldSerial.Client === newSerial.Client &&
                                oldSerial.Sku === newSerial.Sku &&
                                oldSerial.Serial === newSerial.Serial
                        )
                    ) {
                        dispatch(
                            setDialog({
                                title: 'Invalid Serial',
                                content: `Can not add same {client, sku, serial} combination as any in list.
                                                                {${newSerial.Client}, ${newSerial.Sku}, ${newSerial.Serial}} already exists`,
                            })
                        );
                    } else {
                        result.push(newSerial);
                    }
                }
                setSerialsToClear(prev => [...prev, ...result]);
            },
            ...papaparseOptions,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        triggerClearSerials({
            Serials: serialsToClear,
            Reason: reason,
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ m: 1, flex: 1 }}>
                    CSV must have the following headers: <b>Client, Sku, Serial</b>. <br /> Sku must have
                    <b> location postfix</b> (-O, -G, -N, ...).
                </Typography>

                <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 1 }}
                    href={EXAMPLE_CSV_PATH}
                    download="exampleClearSerialUpload.csv"
                    target="_blank"
                >
                    Download Example CSV File
                </Button>
            </Box>

            <FileUploader
                children={
                    <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" sx={{ m: 1, flex: 1 }}>
                            Upload CSV
                        </Button>
                    </Box>
                }
                handleChange={handleFileUpload}
                types={ACCEPTED_FILE_TYPES}
                onTypeError={() => {
                    dispatch(
                        setDialog({
                            title: 'Invalid File Type',
                            content: `Accepted file types: ${ACCEPTED_FILE_TYPES}`,
                        })
                    );
                }}
            />

            <Divider flexItem sx={{ my: 5 }} />

            <SerialStatusDataGrid
                data={serialsToClear}
                onRowDelete={serial => {
                    setSerialsToClear(prev =>
                        prev.filter(
                            s => s.Client !== serial.Client || s.Sku !== serial.Sku || s.Serial !== serial.Serial
                        )
                    );
                }}
            />

            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        label="Reason"
                        variant="filled"
                        sx={{ my: 2, width: '100%' }}
                        name="Reason"
                        required
                        value={reason}
                        onChange={event => {
                            const newValue = event.target.value;
                            setReason(newValue);
                        }}
                    />

                    <Button type="submit" sx={{ flex: 1, mx: 1 }} variant="contained" color="primary">
                        Clear All Serials
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default CsvForm;
