import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import textFieldFocus from '../../../../../../rapidQc/utils/textFieldFocus';
import { SerialScan } from '../../../../../receiversModels';
import { DUPLICATE_SERIAL_COLORS, ScanSerialRowColor } from '../constants';

const SerialTextfield = ({
    rawInput,
    serialInputRef,
    isCompleted,
    pending,
    synced,
    setRawInput,
    numberOfScansExceedsExpected,
    edittable,
    duplicates,
}: {
    rawInput: string;
    serialInputRef: React.RefObject<HTMLInputElement> | null;
    isCompleted: boolean;
    pending: SerialScan[];
    synced: SerialScan[];
    duplicates: string[];
    setRawInput: React.Dispatch<React.SetStateAction<string>>;
    numberOfScansExceedsExpected: boolean;
    edittable: boolean;
}) => {
    React.useEffect(() => {
        setTimeout(() => {
            textFieldFocus({ textFieldRef: serialInputRef });
        }, 100);
    }, []);

    //BUG: cutting all text when input is empty will create error
    //Node.removeChild: The node to be removed is not a child of this node
    const handleInputChange = (input: string) => {
        setRawInput(input);
    };

    const getPlaceholder = () => {
        if (numberOfScansExceedsExpected) return 'Too many serials scanned';

        if (isCompleted) return 'No more scans required';

        return 'Begin scanning...';
    };

    return (
        <Box
            sx={{
                mt: 1,
                flex: 1,
                '.public-DraftEditor-content': {
                    height: '35vh',
                    overflow: 'auto',
                },

                '.DraftEditor-root, .DraftEditor-editorContainer, .public-DraftEditor-content': {
                    backgroundColor: grey['A100'],
                    lineHeight: 2.5,
                },
            }}
        >
            <HighlightWithinTextarea
                value={rawInput}
                highlight={[
                    ...duplicates.map((duplicate, index) => {
                        const duplicateIndex = index % DUPLICATE_SERIAL_COLORS.length;
                        return {
                            highlight: duplicate,
                            component: (props: any) => (
                                <mark style={{ background: DUPLICATE_SERIAL_COLORS[duplicateIndex], padding: 10 }}>
                                    {props.children}
                                </mark>
                            ),
                        };
                    }),
                    {
                        highlight: pending.map(scan => scan.Serial),
                        className: ScanSerialRowColor.Pending.CssClassName,
                        component: (props: any) => (
                            <mark style={{ background: ScanSerialRowColor.Pending.Color, padding: 5 }}>
                                {props.children}
                            </mark>
                        ),
                    },
                    {
                        highlight: synced.map(scan => scan.Serial),
                        className: ScanSerialRowColor.Synced.CssClassName,
                        component: (props: any) => (
                            <mark style={{ background: ScanSerialRowColor.Synced.Color, padding: 5 }}>
                                {props.children}
                            </mark>
                        ),
                    },
                ]}
                onChange={handleInputChange}
                placeholder={getPlaceholder()}
                ref={serialInputRef}
                readOnly={edittable}
            />
        </Box>
    );
};

export default SerialTextfield;
