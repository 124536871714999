import Page from '../../../../common/components/container/Page';
import { breadcrumbNameMap } from '../inbound/const';
import ViewAsns from './ViewAsns';

const ViewAsnsPage = () => {
    return (
        <Page
            title={'Create Inbound with ASN'}
            breadcrumbNameMap={breadcrumbNameMap}
            childrenElement={<ViewAsns shouldNavigateToReceiversPage={false} />}
        />
    );
};

export default ViewAsnsPage;
