import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmDialog = ({
    open,
    handleConfirm,
    handleCancel,
    title = '',
    contents = [],
}: {
    open: boolean;
    handleConfirm: () => void;
    handleCancel: () => void;
    title?: string;
    contents?: string[];
}) => {
    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {contents
                    .filter(content => content)
                    .map((content, index, array) => {
                        return (
                            <>
                                <DialogContentText>{content}</DialogContentText>
                                {index < array.length - 1 && <br />}
                            </>
                        );
                    })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
