import { Box } from '@mui/material';
import monitorImage from '../../common/assets/images/monitor.png';
import ordersImage from '../../common/assets/images/ordersIcon.png';
import FeatureCard from '../mainSection/FeatureCard';
import { RMA_PROCESS_SLUG, RMA_REPORT_SLUG } from './constants';

const RmaMainMenuElement = () => {
    return (
        <Box sx={{ display: 'flex', justifyItems: 'space-between' }}>
            <Box sx={{ flex: 1, mr: 3 }}>
                <FeatureCard
                    image={ordersImage}
                    title={'Process RMAs'}
                    description={'Scan and Process Returned Items'}
                    path={RMA_PROCESS_SLUG}
                    testId={RMA_PROCESS_SLUG}
                />
            </Box>
            <Box sx={{ flex: 1 }}>
                <FeatureCard
                    image={monitorImage}
                    title={'View Report'}
                    description={'View Processed RMAs'}
                    path={RMA_REPORT_SLUG}
                    testId={RMA_REPORT_SLUG}
                />
            </Box>
        </Box>
    );
};

export default RmaMainMenuElement;
