import { Box, styled } from '@mui/material';
import { green, lightBlue, orange, yellow } from '@mui/material/colors';
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/store';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import { formatTypedDate } from '../../../../common/utils/typedCommonUtils';
import { INBOUND_SLUG } from '../../../authentication/slugs';
import receiversApi from '../../receiversApi';
import { AsnFilterModel, Inbound, SubmissionFormItem } from '../../receiversModels';
import { initialForm, setForm, setIsDialogOpen, setMode } from '../../receiversSlice';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
    },
    '& .Announced': {
        backgroundColor: orange['A100'],
        '&:hover': { backgroundColor: orange['A200'] },
    },
    '& .Processing': {
        backgroundColor: yellow['A100'],
        '&:hover': { backgroundColor: yellow['A200'] },
    },

    '& .Accepted': {
        backgroundColor: lightBlue['A100'],
        '&:hover': { backgroundColor: lightBlue['A200'] },
    },
    '& .Completed': {
        backgroundColor: green['A200'],
        '&:hover': { backgroundColor: green['A400'] },
    },
})) as typeof DataGrid;

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const AsnDataGrid = ({
    asnFilter,
    shouldNavigateToReceiversPage,
}: {
    asnFilter: AsnFilterModel;
    shouldNavigateToReceiversPage: boolean;
}) => {
    const dispatch = useAppDispatch();

    const getInboundsResponse = receiversApi.endpoints.getReceiverInbounds.useQuery(
        asnFilter.momCode ? asnFilter.momCode : skipToken
    );

    useNotificationByQueryResponse({
        response: getInboundsResponse,
        errorMessage: 'Failed to get inbounds',
    });

    const handleOpenInbound = (clickedInbound: Inbound) => {
        const inboundItems = clickedInbound.Items.map(
            (item): SubmissionFormItem => {
                return {
                    Item: item.Sku,
                    Upc: item.Upc,
                    LineId: item.LineId,
                    Descript: item.Description,
                    RecCrtncount: 0,
                    RecCaseqty: 0,
                    RecPartial: 0,
                    RecQty: 0,
                    PlQty: item.QtyExpected,
                    Notes: '',
                    CurrentScannedCount: 0,
                    TotalSerialsToScan: 0,
                };
            }
        );

        dispatch(
            setForm({
                ...initialForm,
                Client: asnFilter.momCode || '',
                Po: clickedInbound.POReferenceNumber,
                RecDate: dayjs(clickedInbound.ReceivedDate)?.format() || dayjs().format(),
                Location: clickedInbound.Location || 'California',
                UnitType: clickedInbound.ContainerLoad || 'pallets',
                Items: inboundItems,
                Comment: clickedInbound.Notes,
            })
        );

        dispatch(setIsDialogOpen(true));
        dispatch(setMode('Create'));
    };

    const navigate = useNavigate();

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        const newInbound = params.row as Inbound;
        // handleOpenInbound(newInbound);
        const newRoute = shouldNavigateToReceiversPage
            ? `/receivers/${asnFilter.momCode}/${newInbound.POReferenceNumber}`
            : `/${INBOUND_SLUG}/ASN/${asnFilter.momCode}/${newInbound.POReferenceNumber}`;
        navigate(newRoute);
    };

    const columns: readonly GridColDef<Inbound>[] = [
        {
            field: 'POReferenceNumber',
            headerName: 'PO Reference #',
            width: 180,
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 140,
        },
        {
            field: 'CreatedDate',
            headerName: 'Created Date',
            width: 190,
            valueGetter: (value, row) => formatTypedDate(row.CreatedDate) || 'N/A',
        },
        {
            field: 'ExpectedDeliveryDate',
            headerName: 'Expected Delivery Date',
            width: 190,
            valueGetter: (value, row) => formatTypedDate(row.ExpectedDeliveryDate) || 'N/A',
        },
        {
            field: 'AppointmentDate',
            headerName: 'Appointment Date',
            width: 190,
            valueGetter: (value, row) => formatTypedDate(row.AppointmentDate) || 'N/A',
        },
        {
            field: 'ReceivedDate',
            headerName: 'Received Date',
            width: 190,
            valueGetter: (value, row) => formatTypedDate(row.ReceivedDate) || 'N/A',
        },
    ];

    return (
        <Box>
            <StyledDataGrid
                rows={getInboundsResponse.data || []}
                columns={columns}
                getRowId={row => row.POReferenceNumber}
                sx={{ mt: 2 }}
                onRowClick={handleRowClick}
                slots={{ toolbar: CustomToolbar }}
                density="compact"
                getRowClassName={params => {
                    return params.row.Status;
                }}
            />
        </Box>
    );
};

export default AsnDataGrid;
