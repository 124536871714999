import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { GridRenderEditCellParams, GridTreeNodeWithRender, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import { AssignmentRecord } from '../../models';
import printerApi from '../../printerApi';
import ComputerDatagrid from '../ComputerDatagrid';

//https://mui.com/x/react-data-grid/custom-columns/
export function EditComputer(props: GridRenderEditCellParams<any, AssignmentRecord, number, GridTreeNodeWithRender>) {
    const { id, value, field, formattedValue } = props;

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const apiRef = useGridApiContext();

    const onRowClick = async (clickedId: number) => {
        await apiRef.current.setEditCellValue({ id, field, value: clickedId });
        apiRef.current.stopCellEditMode({ id, field });
        setIsDialogOpen(false);
    };

    const handleClose = async () => {
        setIsDialogOpen(false);
    };

    const getComputersResponse = printerApi.endpoints.getComputers.useQuery();

    return (
        <>
            <Button size="small" onClick={() => setIsDialogOpen(true)}>
                {formattedValue}
            </Button>
            <Dialog onClose={handleClose} open={isDialogOpen} fullWidth maxWidth="lg">
                <DialogTitle>Computer</DialogTitle>
                <DialogContent>
                    <ComputerDatagrid
                        computers={getComputersResponse.data || []}
                        onRowClick={onRowClick}
                        key={JSON.stringify(getComputersResponse.data)}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
