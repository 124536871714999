import Page from '../../../../../common/components/container/Page';
import ViewRecievers from '../../viewReceivers/ViewRecievers';
import { breadcrumbNameMap } from '../const';

const Resume = () => {
    return (
        <Page
            title={'Resume Inbound'}
            childrenElement={<ViewRecievers shouldNavigateToReceiversPage={false} />}
            breadcrumbNameMap={breadcrumbNameMap}
        />
    );
};

export default Resume;
