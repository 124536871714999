import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import { formatDayjsDateWithoutTime } from '../../../../common/utils/dateUtil';
import rmaApi from '../../rmaApi';
import { setRma as setRmaSlice } from '../../rmaSlice';
import { ExpectedItemsDialog } from '../ExpectedItems/ExpectedItemsDialog';

export const ChildRmasDataGrid = React.memo(() => {
    const dispatch = useDispatch();
    const [triggerGetRmaById, rmaByIdResponse] = rmaApi.endpoints.getRmaById.useLazyQuery();
    const [isTriggeredLocally, setIsTriggeredLocally] = React.useState(false);
    const [expectedItemsDialog, setExpectedItemsDialog] = React.useState(false);
    const parentRma = useAppSelector(state => state.rma.parentRma);

    React.useEffect(() => {
        if (!rmaByIdResponse.isUninitialized && !rmaByIdResponse.isFetching && isTriggeredLocally) {
            setIsTriggeredLocally(false);
            if (rmaByIdResponse.data?.Success && rmaByIdResponse.data.Data.length > 0) {
                if (!rmaByIdResponse.data?.Data) {
                    return;
                }
                dispatch(setRmaSlice(rmaByIdResponse.data?.Data[0]));
                setExpectedItemsDialog(true);
            }
        }
    }, [rmaByIdResponse, isTriggeredLocally]);

    const columns: GridColDef[] = [
        {
            field: 'DateUpdated',
            headerName: 'PROCESSED DATE',
            headerAlign: 'center',
            align: 'center',
            flex: 3,
            valueGetter: value => {
                return formatDayjsDateWithoutTime(value);
            },
        },
        {
            field: 'ProcessedBy',
            headerName: 'PROCESSED BY',
            headerAlign: 'center',
            align: 'center',
            flex: 2,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Id',
            headerName: 'DETAILS',
            flex: 3,
            renderCell: (params: any) => {
                return (
                    <Button
                        size="small"
                        onClick={() => {
                            triggerGetRmaById({ momCode: parentRma?.Client, rmaId: params.row.Id });
                            setIsTriggeredLocally(true);
                        }}
                    >
                        SHOW
                    </Button>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <ExpectedItemsDialog showDialog={expectedItemsDialog} handleOpenDialog={setExpectedItemsDialog} />
            <DataGrid
                getRowId={row => row.Id}
                autoHeight
                rows={parentRma?.Rmas || []}
                columns={columns}
                disableRowSelectionOnClick
                scrollbarSize={20}
                hideFooter
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Id', sort: 'desc' }],
                    },
                }}
            />
        </React.Fragment>
    );
});
