import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ThunderstormOutlinedIcon from '@mui/icons-material/ThunderstormOutlined';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
} from '@mui/material';
import { green, orange } from '@mui/material/colors';
import React from 'react';
import { useParams } from 'react-router';
import useSound from 'use-sound';
import successAudio from '../../../../../../../common/assets/audio/success.mp3';
import { useInterval } from '../../../../../../../common/hooks/useInterval';
import useMutationResponse from '../../../../../../../common/hooks/useMutationResponse';
import useNavigationWarning from '../../../../../../../common/hooks/useNavigationWarning';
import textFieldFocus from '../../../../../../rapidQc/utils/textFieldFocus';
import receiversApi from '../../../../../receiversApi';
import { SerialData, SerialScan } from '../../../../../receiversModels';
import ExtraSerialHeader from './ExtraSerialHeader';
import SerialTable from './SerialTable';
import SerialTextfield from './SerialTextfield';
import SerialTextfieldLegend from './SerialTextfieldLegend';

const SYNC_ON_EVERY_SERIAL_COUNT = 5;
const SYNC_TIMER_IN_MS = 15000;

const getDuplicateRegexMatches = (input: string, regex: RegExp | null) => {
    return regex
        ? [...input.matchAll(regex)]
              .map(match => match[0])
              .filter((element, index, array) => array.indexOf(element) !== index)
        : [];
};

const getUniqueRegexMatches = (input: string, regex: RegExp | null) => {
    return regex ? [...new Set([...input.matchAll(regex)].map(match => match[0]))] : [];
};

const AddSerialDialog = ({ item, onClose }: { item: SerialData; onClose: () => void }) => {
    const routeParams: any = useParams();
    const serialInputRef = React.useRef<HTMLInputElement>(null);

    useNavigationWarning({ shouldShowWarningOnNavigationChange: true });

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    const getSerialCachesResponse = receiversApi.endpoints.getSerialCaches.useQuery(
        {
            ReceiverDataId: item.ReceiverDataId,
            ShouldIncludeCompletedSerials: !getReceiverResponse?.data?.RequestAdditionalSerialScans,
        },
        { refetchOnMountOrArgChange: true }
    );
    const [rawInput, setRawInput] = React.useState('');

    const [
        deleteSerialCachesTrigger,
        deleteSerialCachesResponse,
    ] = receiversApi.endpoints.deleteSerialCaches.useMutation();

    useMutationResponse({ response: deleteSerialCachesResponse, errorMessage: 'Failed to delete ongoing serials' });

    const [postSerialCachesTrigger, postSerialCachesResponse] = receiversApi.endpoints.postSerialCaches.useMutation();

    useMutationResponse({ response: postSerialCachesResponse, errorMessage: 'Failed to post ongoing serials' });

    const syncedSerialScans: SerialScan[] =
        getSerialCachesResponse?.data?.map(serial => {
            return {
                Serial: serial,
                Status: 'Synced',
                Icon: <CloudDoneOutlinedIcon />,
            };
        }) || [];

    const rejectedSerialScans: SerialScan[] =
        postSerialCachesResponse?.data?.RegexRejectedSerials?.map(serial => {
            return {
                Serial: serial,
                Status: 'Rejected',
                Icon: <ThunderstormOutlinedIcon />,
            };
        }) || [];

    const regex = item.Regex ? new RegExp(item.Regex, 'gi') : null;

    const localSerialScans: SerialScan[] = getUniqueRegexMatches(rawInput, regex)
        ?.filter(localScan => !syncedSerialScans.some(syncedScan => syncedScan.Serial === localScan))
        ?.map(serial => {
            return {
                Serial: serial,
                Status: 'Pending',
                Icon: <CloudUploadOutlinedIcon />,
            };
        });

    const allSerials = React.useMemo(() => [...localSerialScans, ...rejectedSerialScans, ...syncedSerialScans], [
        localSerialScans.length,
        rejectedSerialScans.length,
        syncedSerialScans.length,
    ]);

    const handleSync = async (shouldCloseWhenDone: boolean = false) => {
        if (!item) return;

        const serialsToSubmit = allSerials.filter(serial => serial.Status === 'Pending').map(serial => serial.Serial);

        if (serialsToSubmit?.length > 0 && !postSerialCachesResponse.isLoading && !getSerialCachesResponse.isFetching)
            await postSerialCachesTrigger({ ReceiverDataId: item.ReceiverDataId, Serials: serialsToSubmit })
                .unwrap()
                .then(() => {
                    if (shouldCloseWhenDone) onClose();
                })
                .catch();

        textFieldFocus({ textFieldRef: serialInputRef });
    };

    useInterval(handleSync, SYNC_TIMER_IN_MS);

    const totalSerialsScanned = (getSerialCachesResponse?.data?.length || 0) + localSerialScans.length;

    const isItemCompleted = totalSerialsScanned === item?.TotalSerialsToScan;
    const numberOfScansExceedsExpected = totalSerialsScanned > item?.TotalSerialsToScan;
    const hasOneMoreSerialThanExpected = totalSerialsScanned === item?.TotalSerialsToScan + 1;

    const syncAndClose = async () => {
        if (localSerialScans.length) await handleSync(true);
        else onClose();
    };

    const [playSuccessAudio] = useSound(successAudio);

    React.useEffect(() => {
        if (isItemCompleted) {
            playSuccessAudio();
        }

        if (
            (localSerialScans.length > SYNC_ON_EVERY_SERIAL_COUNT || isItemCompleted) &&
            !postSerialCachesResponse.isLoading &&
            !numberOfScansExceedsExpected
        ) {
            handleSync();
        }
    }, [localSerialScans.length]);

    const handleDelete = React.useCallback((serials: SerialScan[]) => {
        serials.forEach(serial => {
            setRawInput(oldInput => oldInput.replaceAll(serial.Serial, ''));
        });

        if (serials.some(scan => scan.Status === 'Synced'))
            deleteSerialCachesTrigger({
                ReceiverDataId: item.ReceiverDataId,
                Serials: serials.filter(scan => scan.Status === 'Synced').map(scan => scan.Serial),
            });
    }, []);

    const getBackgroundColor = () => {
        if (numberOfScansExceedsExpected) return orange['A100'];
        if (isItemCompleted) return green['A100'];
        return '';
    };

    const isSerialAddingDone = !getReceiverResponse?.data?.RequestAdditionalSerialScans;

    return (
        <Dialog
            onClose={syncAndClose}
            open={!!item}
            maxWidth={'lg'}
            fullWidth
            sx={{
                flex: 1,
                height: '100%',
            }}
        >
            <DialogTitle variant="h4">
                Sku: {item?.Sku} ({item?.Upc})
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'row', gap: '3%', paddingTop: 10 }}>
                <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                    {numberOfScansExceedsExpected && <ExtraSerialHeader />}
                    <Paper
                        elevation={10}
                        sx={{ display: 'flex', flexDirection: 'column', backgroundColor: getBackgroundColor(), p: 1 }}
                    >
                        <DialogContentText variant="h5">
                            Serials Captured: <b>{totalSerialsScanned}</b> out of <b>{item?.TotalSerialsToScan}</b>
                        </DialogContentText>
                        {!isSerialAddingDone && (
                            <SerialTextfield
                                rawInput={rawInput}
                                setRawInput={setRawInput}
                                serialInputRef={serialInputRef}
                                isCompleted={isItemCompleted}
                                pending={localSerialScans}
                                synced={syncedSerialScans}
                                duplicates={getDuplicateRegexMatches(rawInput, regex)}
                                numberOfScansExceedsExpected={numberOfScansExceedsExpected}
                                edittable={hasOneMoreSerialThanExpected}
                            />
                        )}
                    </Paper>

                    {!isSerialAddingDone && <SerialTextfieldLegend />}
                </Box>

                <Box
                    sx={{
                        flex: 1,
                    }}
                >
                    <SerialTable
                        allSerials={allSerials}
                        handleDelete={handleDelete}
                        allowDelete={!isSerialAddingDone}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {!isSerialAddingDone && (
                    <Button onClick={() => handleSync(false)}>
                        {isItemCompleted ? (
                            <>
                                Done <CloudDoneOutlinedIcon fontSize="large" sx={{ ml: 1 }} />
                            </>
                        ) : (
                            <>
                                Sync <CloudSyncOutlinedIcon fontSize="large" sx={{ ml: 1 }} />
                            </>
                        )}
                    </Button>
                )}

                <Button onClick={syncAndClose}>
                    Continue <ArrowForwardRoundedIcon fontSize="large" sx={{ ml: 1 }} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSerialDialog;
