import { Box, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from '../../../notification/notificationSlice';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import { ReceivedItem, ReceivedItemSerial } from '../../rmaModels';
import { ScanSerialsInput } from './ScanSerialsInput';

export const ScanSerialsForm = ({
    scanSerialRef,
    receivedItem,
    serialQtyLimitReached,
    serials,
    setSerials,
}: {
    scanSerialRef: React.RefObject<HTMLInputElement>;
    receivedItem: ReceivedItem;
    serialQtyLimitReached: boolean;
    serials?: ReceivedItemSerial[];
    setSerials: (serial1: string, serial2: string) => void;
}) => {
    const dispatch = useDispatch();
    const serial2TextfieldRef = useRef<HTMLInputElement>(null);
    const hasSerial2Input = !!receivedItem.SerialVerify?.Serial2Alias;

    const [serial1Input, setSerial1Input] = React.useState('');
    const [serial2Input, setSerial2Input] = React.useState('');

    const passedSerialRegex = (regexString: string | null | undefined, serial: string | null | undefined) => {
        if (!!!serial) {
            return false;
        }
        if (!!!regexString) {
            return true;
        }
        if (!!regexString) {
            let regex = new RegExp(regexString);
            return regex.test(serial);
        }
        return true;
    };

    const showRegexErrorDialog = (alias: string | null | undefined) => {
        dispatch(
            setDialog({
                title: `INVALID ${alias || 'SCANNED'} NUMBER`,
                content: `This is an invalid ${alias || 'scanned'} number for this item.`,
                color: red['A100'],
            })
        );
    };

    const showDuplicateErrorDialog = (serial: string | null | undefined) => {
        dispatch(
            setDialog({
                title: `Serial Already Entered`,
                content: `"${serial}" has already been scanned.`,
                color: red['A100'],
            })
        );
    };

    const submitSerial = () => {
        if (!!serial1Input || !!serial2Input) {
            setSerials(serial1Input, serial2Input);
            setSerial1Input('');
            setSerial2Input('');
            textFieldFocus({ textFieldRef: scanSerialRef });
        }
    };

    const handleScanningSerial1 = () => {
        if (!!serial1Input) {
            if (!passedSerialRegex(receivedItem.SerialVerify?.Serial1Regex, serial1Input)) {
                showRegexErrorDialog(receivedItem.SerialVerify?.Serial1Alias);
                setSerial1Input('');
                return;
            }

            const serialExists = receivedItem.ItemUnitDetails.some(
                item =>
                    item.Serials.some(serial => serial.Serial1 === serial1Input) ||
                    serials?.some(serial => serial.Serial1 === serial1Input)
            );

            if (!!serialExists && !receivedItem.AllowSerialDup) {
                showDuplicateErrorDialog(serial1Input);
                setSerial1Input('');
                return;
            }

            // Add to serial list if only serial1 input
            if (!hasSerial2Input || !!serial2Input) {
                submitSerial();
                // Focus on second input if serial2 input exists
            } else if (!!!serial2Input) {
                textFieldFocus({ textFieldRef: serial2TextfieldRef });
            }
        }
    };

    const handleScanningSerial2 = () => {
        if (!passedSerialRegex(receivedItem.SerialVerify?.Serial2Regex, serial2Input)) {
            showRegexErrorDialog(receivedItem.SerialVerify?.Serial2Alias);
            setSerial2Input('');
            return;
        }

        const serialExists = receivedItem.ItemUnitDetails.some(
            item =>
                item.Serials.some(serial => serial.Serial2 === serial2Input) ||
                serials?.some(serial => serial.Serial2 === serial2Input)
        );

        if (!!serialExists && !receivedItem.AllowSerialDup) {
            showDuplicateErrorDialog(serial2Input);
            setSerial2Input('');
            return;
        }

        if (!!serial2Input) {
            if (!!serial1Input) {
                submitSerial();
            } else {
                textFieldFocus({ textFieldRef: scanSerialRef });
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mt: 2 }}>
                <ScanSerialsInput
                    label={`SCAN ${receivedItem.SerialVerify?.Serial1Alias || 'SERIAL'} NUMBER`}
                    textfieldReference={scanSerialRef}
                    currentSerial={serial1Input}
                    setCurrentSerial={setSerial1Input}
                    submitInput={handleScanningSerial1}
                    disabled={serialQtyLimitReached}
                    autoFocus={true}
                />
            </Box>
            {hasSerial2Input && (
                <Box sx={{ mt: 2 }}>
                    <ScanSerialsInput
                        label={`SCAN ${receivedItem.SerialVerify?.Serial2Alias || 'SERIAL'} NUMBER`}
                        textfieldReference={serial2TextfieldRef}
                        currentSerial={serial2Input}
                        setCurrentSerial={setSerial2Input}
                        submitInput={handleScanningSerial2}
                        disabled={serialQtyLimitReached}
                    />
                </Box>
            )}
            {!!hasSerial2Input && (
                <Button
                    disabled={serialQtyLimitReached}
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                    onClick={submitSerial}
                >
                    FINISH THIS SCAN
                </Button>
            )}
        </Box>
    );
};
