import { Box } from '@mui/material';
import React from 'react';
import { CompanyDropdownV2 } from '../../../../common/components/dropdown/CompanyDropdownV2';
import { AsnFilterModel } from '../../receiversModels';

const AsnFilter = ({ setAsnFilter }: { setAsnFilter: React.Dispatch<React.SetStateAction<AsnFilterModel>> }) => {
    return (
        <Box>
            <CompanyDropdownV2
                setCompany={newValue => {
                    setAsnFilter(prev => {
                        return { ...prev, momCode: newValue };
                    });
                }}
                label={'Company'}
                sx={{ flex: 3, m: 1 }}
            />
        </Box>
    );
};

export default AsnFilter;
