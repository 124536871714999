import { INBOUND_SLUG } from '../../../authentication/slugs';

export const breadcrumbNameMap: { [key: string]: string } = {
    '/receivers': 'Receivers',
    [`/${INBOUND_SLUG}`]: 'Inbound',
    [`/${INBOUND_SLUG}/create`]: 'Create',
    [`/${INBOUND_SLUG}/create/new`]: 'New',
    [`/${INBOUND_SLUG}/resume`]: 'Resume',
    [`/${INBOUND_SLUG}/asn`]: 'ASN',
};
