import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';

import { Box, Chip, Typography } from '@mui/material';
import { useAppSelector } from '../../../../../app/store';

type PlayedState = 'Played' | 'Not Played' | 'Partial Played';

interface PlayedObject {
    value: PlayedState;
    icon: JSX.Element;
    color: string;
}

const Header = () => {
    const mode = useAppSelector(state => state.receivers.mode);

    const { Number, Played, Items, RequestAdditionalSerialScans } = useAppSelector(state => state.receivers.form);

    const getPlayState = (): PlayedObject => {
        if (!Played) return { value: 'Not Played', icon: <CloseIcon />, color: 'red' };

        const isPartiallyPlayed = Items.some(item => !item?.Transid);
        if (isPartiallyPlayed) return { value: 'Partial Played', icon: <RemoveIcon />, color: 'orange' };

        return { value: 'Played', icon: <DoneIcon />, color: 'green' };
    };

    const headerText = !Number ? `Receiver` : `Receiver: ${Number}`;

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
                <Typography variant="h3">{headerText}</Typography>
                <Typography variant="h4" color={'error'}>
                    <b>{RequestAdditionalSerialScans && `Warning: Incomplete Serial Scan`}</b>
                </Typography>
            </Box>
            {!!Number && (
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    <Chip
                        label={getPlayState().value}
                        icon={getPlayState().icon}
                        sx={{
                            backgroundColor: getPlayState().color,
                            color: 'white',
                            '& .MuiChip-icon': {
                                color: 'white',
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Header;
