import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../../app/store';
import Page from '../../../../../common/components/container/Page';
import useNotificationByQueryResponse from '../../../../../common/hooks/useNotificationByQueryResponse';
import { INBOUND_SLUG } from '../../../../authentication/slugs';
import receiversApi from '../../../receiversApi';
import { SubmissionFormItem } from '../../../receiversModels';
import { initialForm, setForm } from '../../../receiversSlice';
import DialogForm from '../../form/viewForm/DialogForm';
import { breadcrumbNameMap } from '../const';

const CreateWithAsn = () => {
    const dispatch = useAppDispatch();
    const routeParams: any = useParams();

    const getReceiverInboundResponse = receiversApi.endpoints.getReceiverInbound.useQuery({
        momCode: routeParams.momCode,
        poReferenceNumber: routeParams.poReferenceNumber,
    });

    useNotificationByQueryResponse({
        response: getReceiverInboundResponse,
        errorMessage: 'Could not get inbound data',
        successFinishedFunction: () => {
            if (getReceiverInboundResponse?.data) {
                const inboundItems = getReceiverInboundResponse.data.Items.map(
                    (item): SubmissionFormItem => {
                        return {
                            Item: item.Sku,
                            Upc: item.Upc,
                            LineId: item.LineId,
                            Descript: item.Description,
                            RecCrtncount: 0,
                            RecCaseqty: 0,
                            RecPartial: 0,
                            RecQty: 0,
                            PlQty: item.QtyExpected,
                            Notes: '',
                            CurrentScannedCount: 0,
                            TotalSerialsToScan: 0,
                        };
                    }
                );

                dispatch(
                    setForm({
                        ...initialForm,
                        Client: routeParams.momCode || '',
                        Po: getReceiverInboundResponse.data.POReferenceNumber,
                        RecDate: dayjs().format(),
                        Location: getReceiverInboundResponse.data.Location || 'California',
                        UnitType: getReceiverInboundResponse.data.ContainerLoad || 'pallets',
                        Items: inboundItems,
                        Comment: getReceiverInboundResponse.data.Notes,
                    })
                );
            }
        },
    });

    return (
        <Page
            title={'Create New Inbound With ASN'}
            childrenElement={<DialogForm hasAsn={true} getCreateRedirectLink={id => `/${INBOUND_SLUG}/resume/${id}`} />}
            maxWidth="xl"
            breadcrumbNameMap={breadcrumbNameMap}
        />
    );
};

export default CreateWithAsn;
