import { Box, Button } from '@mui/material';
import { useState } from 'react';
import {
    RMA_REPORT_RESULTS_DEFAULT_COUNT,
    RMA_REPORT_RESULTS_MAX_COUNT,
    SEARCH_RMA_ID,
    SEARCH_TRACKING,
} from '../constants';
import useSetRmaSearchResults from '../hooks/useSetRmaSearchResults';
import rmaApi from '../rmaApi';
import { GetRmaRequest, Rma } from '../rmaModels';
import { ReportResultsDatagrid } from './ReportResultsDatagrid';
import { RmaReportFilter } from './RmaReportFilter';

const DEFAULT_FILTERS: GetRmaRequest = {
    ProcessedOnly: true,
    OmitOriginalOrder: true,
    OmitSerialValidationInfo: true,
    ResultsCountLimit: RMA_REPORT_RESULTS_DEFAULT_COUNT,
    ResultsPage: 0,
    Status: 0,
};

export const LandingPageDisplay = () => {
    const [searchFilter, setSearchFilter] = useState<GetRmaRequest>(DEFAULT_FILTERS);
    const [triggerSearchRmas, searchRmasResponse] = rmaApi.endpoints.getRma.useLazyQuery();
    const [triggerSearchRmaById, searchRmaByIdResponse] = rmaApi.endpoints.getRmaById.useLazyQuery();
    const [currentResultPage, setCurrentResultPage] = useState(0);

    const [searchResults, setSearchResults] = useState<Rma[]>([]);
    const [searchByDropDownValue, setSearchByDropDownValue] = useState<string>(SEARCH_TRACKING);

    const handleReset = () => {
        setSearchFilter({ ...DEFAULT_FILTERS });
        setSearchByDropDownValue(SEARCH_TRACKING);
    };

    const handleSearch = (pageNum: number) => {
        setCurrentResultPage(pageNum);
        if (searchByDropDownValue == SEARCH_RMA_ID && !!searchFilter?.Query) {
            const rmaId = parseInt(searchFilter.Query.match(/\d+/g)?.join('') || '0');
            triggerSearchRmaById({ momCode: null, rmaId: rmaId });
        } else {
            triggerSearchRmas({ ...searchFilter, ResultsPage: pageNum });
        }
    };

    useSetRmaSearchResults({ response: searchRmasResponse, setRmaSearchResults: setSearchResults });
    useSetRmaSearchResults({ response: searchRmaByIdResponse, setRmaSearchResults: setSearchResults });

    return (
        <Box sx={{ backgroundColor: 'white', p: 3, display: 'flex', flexDirection: 'column' }}>
            <RmaReportFilter
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                searchByDropDownValue={searchByDropDownValue}
                setSearchByDropDownValue={setSearchByDropDownValue}
            />
            <Box sx={{ display: 'flex' }}>
                <Button size="large" variant="contained" color="secondary" sx={{ flex: 1, m: 1 }} onClick={handleReset}>
                    RESET FILTERS
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    sx={{ flex: 7, m: 1 }}
                    onClick={() => {
                        handleSearch(0);
                    }}
                >
                    SEARCH
                </Button>
            </Box>
            <Box sx={{ m: 1 }}>
                <ReportResultsDatagrid rmaResults={searchResults} />
            </Box>
            <Box sx={{ display: 'flex' }}>
                {currentResultPage > 0 && (
                    <Button
                        size="large"
                        variant="contained"
                        sx={{ flex: 2, m: 1 }}
                        onClick={() => {
                            handleSearch(currentResultPage - 1);
                        }}
                    >
                        PREV {searchFilter?.ResultsCountLimit} RESULTS
                    </Button>
                )}
                {searchResults.length >=
                    (!!searchFilter?.ResultsCountLimit
                        ? searchFilter?.ResultsCountLimit
                        : RMA_REPORT_RESULTS_MAX_COUNT) && (
                    <Button
                        size="large"
                        variant="contained"
                        sx={{ flex: 5, m: 1 }}
                        onClick={() => {
                            handleSearch(currentResultPage + 1);
                        }}
                    >
                        NEXT {searchFilter?.ResultsCountLimit} RESULTS
                    </Button>
                )}
            </Box>
        </Box>
    );
};
