import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Chip, Typography } from '@mui/material';
import { useParams } from 'react-router';
import useQueryResponse from '../../../../../../common/hooks/useQueryResponse';
import receiversApi from '../../../../receiversApi';

type CompletedStateValue = 'Completed' | 'Not Started' | 'In Progress';

interface CompletedState {
    value: CompletedStateValue;
    icon: JSX.Element;
    color: string;
}

const AddSerialHeader = () => {
    const routeParams: any = useParams();

    const getReceiverResponse = receiversApi.endpoints.getReceiver.useQuery(routeParams.receiverId);

    const getScannedSerialsResponse = receiversApi.endpoints.getAddSerialData.useQuery({
        ReceiverId: routeParams.receiverId,
        ShouldIncludeCompletedSerials: !getReceiverResponse?.data?.RequestAdditionalSerialScans,
    });

    useQueryResponse({ response: getScannedSerialsResponse, errorMessage: 'Failed to get serial data' });

    const getCompletedState = (): CompletedState => {
        if (!getReceiverResponse?.data?.RequestAdditionalSerialScans)
            return { value: 'Completed', icon: <DoneIcon />, color: 'green' };

        const isInProgress = getScannedSerialsResponse?.data?.some(item => item.CurrentScannedCount > 0);
        if (isInProgress) return { value: 'In Progress', icon: <RemoveIcon />, color: 'orange' };

        return { value: 'Not Started', icon: <CloseIcon />, color: 'orange' };
    };

    const completedState = getCompletedState();

    return (
        <Box sx={{ p: 1, m: 1, display: 'flex' }}>
            <Typography variant="h3" sx={{ flex: 1 }}>
                Receiver: {getReceiverResponse?.data?.Number} ({getReceiverResponse?.data?.Client})
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Chip
                    label={completedState.value}
                    icon={completedState.icon}
                    sx={{
                        backgroundColor: completedState.color,
                        color: 'white',
                        '& .MuiChip-icon': {
                            color: 'white',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default AddSerialHeader;
