import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { getReceiverPdfUrl } from '../../../../app/services/urlFunctions';
import { RootState, useAppSelector } from '../../../../app/store';
import useMutationResponse from '../../../../common/hooks/useMutationResponse';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import receiversApi from '../../oldReceiversApi';
import { PlayReceiverPayload, PlayReceiverPayloadItem, Receiver, SubmissionForm } from '../../receiversModels';

interface ReceiversDataGridProps {
    handleContinueWithClickedRow: (newForm: SubmissionForm) => void;
}

const ReceiversDataGrid: React.FC<ReceiversDataGridProps> = ({ handleContinueWithClickedRow }): JSX.Element => {
    // const { receivers } = useSelector((state: RootStateOrAny) => state.receivers);
    const getReceiversRequest = useAppSelector((state: RootState) => state.oldReceivers.getReceiversRequest);

    const { data: receivers } = receiversApi.endpoints.getReceiversDeprecated.useQuery(
        !!getReceiversRequest?.momCode ? getReceiversRequest : skipToken
    );
    const [triggerPlayReceivers, playReceiversResponse] = receiversApi.endpoints.playReceiverDeprecated.useMutation();

    useMutationResponse({
        response: playReceiversResponse,
        errorMessage: 'Unable to play receiver1: ' + JSON.stringify(playReceiversResponse.error),
    });

    const renderBooleanCell = (value: boolean) => {
        return value ? (
            <CheckIcon
                style={{
                    color: green[500],
                }}
            />
        ) : (
            <CloseIcon
                style={{
                    color: red[500],
                }}
            />
        );
    };

    const columns: readonly GridColDef<Receiver>[] = [
        {
            field: 'Number',
            headerName: 'Receiver No.',
            width: 80,
            headerClassName: 'wrapping--header',
        },
        {
            field: 'RecDate',
            headerName: 'Received Date',
            width: 100,
            valueGetter: (value, row) => formatDateWithoutTime(row.RecDate),
        },
        {
            field: 'RecBy',
            headerName: 'Received By',
            width: 100,
        },
        {
            field: 'Print',
            headerName: '',
            width: 80,
            renderCell: (params: any) => {
                return (
                    <Button
                        variant="outlined"
                        target="_blank"
                        href={getReceiverPdfUrl(params.row.Client, params.row.Number)}
                        onClick={event => {
                            event.stopPropagation();
                        }}
                    >
                        Print
                    </Button>
                );
            },
        },
        {
            field: 'ScDeliveryApt',
            headerName: 'Has Delivery Appointment?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScDeliveryApt);
            },
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'ScPl',
            headerName: 'Has Packing List?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScPl);
            },
        },
        {
            field: 'ScPlqty',
            headerName: 'Correct Quantity?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScPlqty);
            },
        },
        {
            field: 'ScCartonMark',
            headerName: 'Correct UPS?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScCartonMark);
            },
        },
        {
            field: 'ScBoxCondition',
            headerName: 'Good Box Condition?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScBoxCondition);
            },
        },
        {
            field: 'ScPalletCondition',
            headerName: 'Good Pallet Condition?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.ScPalletCondition);
            },
        },

        {
            field: 'UnitType',
            headerName: 'Unit Type',
            width: 80,
        },
        {
            field: 'Project',
            headerName: 'Project Type',
            width: 200,
        },
        {
            field: 'Played',
            headerName: 'Played?',
            width: 57,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.Played);
            },
        },
        {
            field: 'Play',
            headerName: '',
            width: 80,
            renderCell: (params: any) => {
                return (
                    <Button
                        variant="outlined"
                        disabled={params.row.Played}
                        sx={{ width: '100%' }}
                        onClick={event => {
                            event.stopPropagation();
                            const clickedReceiver: SubmissionForm = params.row;
                            const items: PlayReceiverPayloadItem[] = clickedReceiver.Items.map(
                                (item): PlayReceiverPayloadItem => {
                                    return { Id: item.Id || -1, Sku: item.Item, RecQty: item.RecQty };
                                }
                            );
                            const result: PlayReceiverPayload = {
                                MomCode: clickedReceiver.Client,
                                RecNumber: clickedReceiver.Number || 0,
                                Items: items,
                            };
                            triggerPlayReceivers({ momCode: clickedReceiver.Client, body: result });
                        }}
                    >
                        Play
                    </Button>
                );
            },
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        handleContinueWithClickedRow(params.row as SubmissionForm);
    };

    return (
        <React.Fragment>
            <DataGrid
                disableRowSelectionOnClick
                rows={receivers || []}
                columns={columns}
                getRowId={row => row.Number}
                sx={{
                    m: 1,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: 10,
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        textAlign: 'center',
                        textOverflow: 'clip',
                    },
                }}
                initialState={{
                    // ...receivers.initialState,
                    pagination: { paginationModel: { pageSize: 8 } },
                    sorting: {
                        sortModel: [{ field: 'Number', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[8, 15, 20]}
                onRowClick={handleRowClick}
            />
        </React.Fragment>
    );
};

export default ReceiversDataGrid;
