import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from '../../../../app/store';
import { hasError } from '../../utilities';

export const ProcessedRmaDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);

    if (!!rma && hasError(rma.Status) && !!rma.ProcessedHistory?.Id) {
        return <Typography color="warning.main">RMA SUBMITTED WITH ERRORS (ID: {rma?.Id}): CONTACT ADMIN</Typography>;
    }

    if (!!rma && hasError(rma.Status)) {
        return <Typography color="error.main">ERROR PROCESSING RMA (ID: {rma?.Id}): CONTACT ADMIN</Typography>;
    }
    if (!rma?.ProcessedHistory?.Id) {
        return <Typography color="warning.main"> NO ORDER ID CREATED: RMA HAS ALL UNKNOWN SKUS</Typography>;
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography fontWeight={'fontWeightMedium'} color="success.main">
                RMA PROCESSED
            </Typography>
            <Box display="flex" alignItems="center" sx={{ pl: 1 }}>
                <Typography fontWeight={'fontWeightMedium'} color="success.main">
                    ALTERNATE ORDER #: RMA{rma.Id}
                </Typography>
                <Tooltip title={'Processed RMA order may take a few minutes to be created.'} placement="top-end">
                    <InfoOutlinedIcon color="primary" fontSize="small" sx={{ ml: 1, mb: 0.5 }} />
                </Tooltip>
            </Box>
        </Box>
    );
};
