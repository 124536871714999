import styled from '@emotion/styled';
import { NoteAdd } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener } from '@mui/x-data-grid';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../../app/store';
import { renderBooleanCell } from '../../../../../common/components/icon/BooleanCell';
import { INBOUND_SLUG } from '../../../../authentication/slugs';
import { SubmissionFormItem } from '../../../receiversModels';
import DeleteItemConfirmDialog from './DeleteItemConfirmDialog';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .clickable': {
        '&:hover': { cursor: 'pointer' },
    },

    '& .pending': {
        backgroundColor: yellow['A100'],
        '&:hover': { backgroundColor: yellow['A200'] },
    },

    '& .pendingAndClickable': {
        backgroundColor: yellow['A100'],

        '&:hover': { cursor: 'pointer', backgroundColor: yellow['A200'] },
    },

    '& .complete': {
        backgroundColor: green['A200'],
        '&:hover': { backgroundColor: green['A400'] },
    },

    '& .MuiDataGrid-actionsCell': {
        gridGap: 0,
    },
})) as typeof DataGrid;

const ItemsDataGrid = memo(
    ({
        items,
        handleItemClick,
        handleItemDelete,
        hasAsn,
    }: {
        items: SubmissionFormItem[];
        handleItemClick: (item: SubmissionFormItem) => void;
        handleItemDelete: (item: SubmissionFormItem) => void;
        hasAsn: boolean;
    }) => {
        const mode = useAppSelector(state => state.receivers.mode);
        const navigate = useNavigate();

        const [deleteDialogItem, setDeleteDialogItem] = React.useState<SubmissionFormItem | undefined>();

        const handleRowDelete = (row: SubmissionFormItem) => () => {
            setDeleteDialogItem(row);
        };

        const { Number, RequestAdditionalSerialScans } = useAppSelector(state => state.receivers.form);

        const handleAddSerialsClick = (row: SubmissionFormItem) => () => {
            navigate(`/${INBOUND_SLUG}/resume/${Number}/serial`);
        };

        const columns: readonly GridColDef<SubmissionFormItem>[] = [
            {
                field: 'Item',
                headerName: 'SKU',
                flex: 2,
            },
            {
                field: 'Descript',
                headerName: 'Description',
                flex: 3,
            },
            {
                field: 'LineId',
                headerName: 'Line ID',
                flex: 1,
            },
            {
                field: 'RecCrtncount',
                headerName: '# per Ctn',
                flex: 1,
            },
            {
                field: 'RecCaseqty',
                headerName: '# Ctns',
                flex: 1,
            },
            {
                field: 'RecPartial',
                headerName: 'Loose Qty',
                flex: 1,
            },
            {
                field: 'RecQty',
                headerName: 'Total',
                flex: 1,
            },
            {
                field: 'PlQty',
                headerName: "Expt'd",
                flex: 1,
            },
            {
                field: 'Difference',
                headerName: 'Diff.',
                flex: 1,
                valueGetter: (value, row) => row.RecQty - row.PlQty,
            },
            {
                field: 'Transid',
                headerName: 'Played',
                flex: 1,
                renderCell: (params: any) => {
                    return renderBooleanCell(!!params?.row?.Transid);
                },
            },
            {
                field: 'Notes',
                headerName: 'Notes',
                flex: 2,
            },
            {
                field: 'CurrentScannedCount',
                headerName: 'Serials Scanned',
                flex: 1,
                valueGetter: (value, row) => (row.TotalSerialsToScan ? row.CurrentScannedCount : ''),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                flex: 1,
                cellClassName: 'actions',
                getActions: params => {
                    let result = [];

                    if (params.row.CurrentScannedCount < params.row.TotalSerialsToScan && RequestAdditionalSerialScans)
                        result.push(
                            <GridActionsCellItem
                                icon={
                                    <Tooltip title="Add Serial">
                                        <IconButton>
                                            <NoteAdd />
                                        </IconButton>
                                    </Tooltip>
                                }
                                label="Add Serial"
                                className="textPrimary"
                                onClick={handleAddSerialsClick(params.row)}
                                color="inherit"
                            />
                        );

                    if (mode !== 'View')
                        result.push(
                            <GridActionsCellItem
                                icon={
                                    <Tooltip title="Delete">
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                label="Add Serial"
                                className="textPrimary"
                                onClick={handleRowDelete(params.row)}
                                color="inherit"
                            />
                        );

                    return result;
                },
            },
        ];

        const handleRowClick: GridEventListener<'rowClick'> = params => {
            const item = params.row as SubmissionFormItem;
            handleItemClick(item);
        };

        return (
            <>
                <StyledDataGrid
                    rows={items}
                    columns={columns}
                    getRowId={row => row.Id || row.Item}
                    density="compact"
                    columnVisibilityModel={{
                        Transid: mode !== 'Create',
                        Descript: !hasAsn,
                        LineId: hasAsn,
                        PlQty: hasAsn,
                        Difference: hasAsn,
                    }}
                    onRowClick={handleRowClick}
                    disableRowSelectionOnClick
                    getRowClassName={params => {
                        if (
                            !params.row.LineId &&
                            params.row.CurrentScannedCount < params.row.TotalSerialsToScan &&
                            RequestAdditionalSerialScans
                        )
                            return 'pendingAndClickable';
                        if (!params.row.LineId) return 'clickable';
                        if (params.row.RecQty === 0) return 'pending';
                        if (params.row.RecQty > 0) return 'complete';

                        return '';
                    }}
                />
                <DeleteItemConfirmDialog
                    item={deleteDialogItem}
                    handleCancel={() => setDeleteDialogItem(undefined)}
                    handleConfirm={() => {
                        if (deleteDialogItem) handleItemDelete(deleteDialogItem);
                        setDeleteDialogItem(undefined);
                    }}
                />
            </>
        );
    }
);

export default ItemsDataGrid;
